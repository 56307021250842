/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Organization } from "@medplum/fhirtypes";

// Organization.ts
export const createOrganization = (formData: { name: any; providerMobile: any; providerEmail: any; street: string; city: any; state: any; postalCode: any; status: string; npi: any; service: any; }, providerId: string | undefined) => {
    const organization: Organization = {
        resourceType: 'Organization',
        name: formData.name,
        telecom: [
            {
                system: 'phone',
                value: formData.providerMobile,
            },
            {
                system: 'email',
                value: formData.providerEmail,
            },
        ],
        address: [
            {
                line: [formData.street],
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
            },
        ],
        type: [
            {
                coding: [
                    {
                        code: 'ProviderGroup',
                        display: "ProviderGroup"
                    },
                ],
            },
        ],
        active: formData.status === 'true',
        extension: [
            {
                url: 'http://organization-type',
                valueString: 'ProviderGroup',
            }
        ],
    };

    if (formData.npi) {
        organization?.extension?.push({
            url: 'http://providergroup-npi',
            valueString: formData.npi,
        });
    }

    if (formData.service) {
        organization?.extension?.push({
            url: 'http://providergroup-services',
            valueString: formData.service,
        });
    }

    if(providerId) {
        organization.id = providerId;
    }
    return organization;
}