import { Title } from '@mantine/core';
import { Logo, SignInForm, useMedplumProfile } from '@medplum/react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig, isRegisterEnabled } from './config';
import Carousel from './components/carousel.component';

export function SignInPage(): JSX.Element {
  const profile = useMedplumProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  const slides = [
    { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
    { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
  ];

  const navigateToNext = useCallback(() => {
    // only redirect to next if it is a pathname to avoid redirecting
    // to a maliciously crafted URL, e.g. /signin?next=https%3A%2F%2Fevil.com
    const nextUrl = searchParams.get('next');
    navigate(nextUrl?.startsWith('/') ? nextUrl : '/Organization');
  }, [searchParams, navigate]);

  useEffect(() => {
    if (profile && searchParams.has('next')) {
      navigateToNext();
    }
  }, [profile, searchParams, navigateToNext]);

  return (
    // <SignInForm
    //   onSuccess={() => navigateToNext()}
    //   onForgotPassword={() => navigate('/resetpassword')}
    //   onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
    //   googleClientId={config.googleClientId}
    //   login={searchParams.get('login') || undefined}
    //   projectId={searchParams.get('project') || undefined}
    // >
    //   <Logo size={150} />
    //   <Title>Sign in to Medplum</Title>
    //   {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
    // </SignInForm>
    <div className="tw-flex tw-min-h-screen tw-sm:block">
      <div className='tw-flex-initial tw-w-2/4 banner-bg-2 tw-grid tw-items-center'>
        <div className="tw-w-2/3">
          <Logo size={180} />
        </div>
        <SignInForm
          onSuccess={() => navigateToNext()}
          onForgotPassword={() => navigate('/resetpassword')}
          onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
          googleClientId={config.googleClientId}
          login={searchParams.get('login') || undefined}
          projectId={searchParams.get('project') || undefined}
        >
          <Title>Sign In</Title>
          {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
        </SignInForm>
      </div>
      <div className="tw-w-1/2 banner-bg-1">
          <Carousel slides={slides} />
      </div>
    </div>
  );
}
