/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Grid, Text, Button, Card, Box, Divider, Loader } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkInputValue, getOrganizationDefaultFormData } from '../../utils/util';
import AdminDetails from '../AdminDetails';
import { useMedplum } from '@medplum/react';
import { Organization, Resource } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import { checkUserExists, createBrandAdmin, createUpdateBrand } from '../../utils/CustomAPI';
import OrganizationDetails from './OrganizationDetails';
import TitleComponent from '../../components/TitleComponent';

interface formData {
  name: string;
  brandMobile: string;
  brandEmail: string;
  status: string;
  superBill: boolean;
  stateMatch: boolean;
  summary: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
}

const CreateOrganization = () => {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const orgId = useParams().id;
  const location = useLocation();
  const { adminData } = location?.state || {};
  const [formData, setFormData] = useState<formData>(getOrganizationDefaultFormData);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch organization data and set form data
  useEffect(() => {
    if (orgId) {
      medplum
        .readResource('Organization', orgId)
        .then((res: any) => {
          const telecomMap = res.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
          const extensionMap = res.extension.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.url]: curr.valueString }),
            {}
          );
          const extensionMapWithBoolena = res.extension.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.url]: curr.valueBoolean }),
            {}
          );

          setFormData((prevData: any) => ({
            ...prevData,
            name: res.name,
            brandMobile: telecomMap['phone'] || '',
            brandEmail: telecomMap['email'] || '',
            status: res.active ? 'true' : 'false',
            superBill: extensionMapWithBoolena['http://brand-superbill'] || false,
            stateMatch: extensionMapWithBoolena['http://brand-statematch'] || false,
            summary: extensionMap['http://brand-summary'] || '',
            first_name: adminData?.first_name || '',
            last_name: adminData?.last_name || '',
            phone: adminData?.phone || '',
            email: adminData?.email || '',
          }));
        })
        .catch((error: any) => {
          console.error('Error reading resource:', error);
          showNotification({ color: 'red', message: 'Failed' });
        });
    }
  }, []);

  // Handle input change value and set form data
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  };

  // Handle checkbox change and set form data
  const handleCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // Handle form submit
  const handleSubmit = () => {
    //create organization resource json
    const organization: Organization = {
      resourceType: 'Organization',
      name: formData.name,
      telecom: [
        {
          system: 'phone',
          value: formData.brandMobile,
        },
        {
          system: 'email',
          value: formData.brandEmail,
        },
      ],
      type: [
        {
          coding: [
            {
              code: 'Brand',
              display: 'Brand',
            },
          ],
        },
      ],
      active: formData.status === 'true',
      extension: [
        {
          url: 'http://organization-type',
          valueString: 'brnad',
        },
      ],
    };

    if (formData.superBill) {
      organization?.extension?.push({
        url: 'http://brand-superbill',
        valueBoolean: formData.superBill,
      });
    }

    if (formData.stateMatch) {
      organization?.extension?.push({
        url: 'http://brand-statematch',
        valueBoolean: formData.stateMatch,
      });
    }

    if (formData.summary) {
      organization?.extension?.push({
        url: 'http://brand-summary',
        valueString: formData.summary,
      });
    }

    if (orgId) {
      organization.id = orgId;
    }
    if (formData.password !== formData.confirm_password) {
      setError(true);
      return;
    }
    setIsLoading(true);
    createUpdateBrand(medplum, organization)
      .then((resource) => {
        brandAdminCreate(resource);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  //create and update brand admin call
  const brandAdminCreate = async (resource: Resource) => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      email: formData.email,
      password: formData.password,
      brand_id: resource.id,
      profile_id: adminData?.profile_id,
      id: adminData?.id,
    };

    // Check if user exists
    try {
      const response = await checkUserExists(medplum, { email: formData.email });
      if (response?.exists) {
        showNotification({ color: 'red', message: 'Email already exists' });
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showNotification({ color: 'red', message: 'Error checking user existence' });
      return;
    }

    try {
      createBrandAdmin(medplum, payload)
        .then(() => {
          setIsLoading(false);
          showNotification({
            color: 'green',
            message: `${orgId ? 'Brand updated successfully' : 'Brand created successfully'}`,
          });
          navigate(`/Organization`);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          showNotification({ color: 'red', message: normalizeErrorString(err) });
        });
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      showNotification({ color: 'red', message: normalizeErrorString(err) });
    }
  };

  return (
    <>
      <Box px="lg" pt="sm" mt={65} pb={20}>
        <TitleComponent title={`${orgId ? 'Update' : 'Create new'} brand`} />
      </Box>

      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="org-form card-bg">
          <Card shadow="sm" radius="lg">
            <OrganizationDetails
              formData={formData}
              handleInputChange={handleInputChange}
              handleCheckBoxChange={handleCheckBoxChange}
            />
            <Divider my={20} />
            <Text style={{ fontSize: '18px', color: '#272D41', fontWeight: '500' }} className="tw-my-2">
              Admin Details{' '}
            </Text>
            <AdminDetails formData={formData} error={error} handleInputChange={handleInputChange} />
          </Card>
        </Box>

        {/* Buttons */}
        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              onClick={() => navigate(`/Organization`)}
              variant="outline"
              type="button"
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }} disabled={isLoading}>
              {isLoading ? (
                <>
                  {orgId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : orgId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default CreateOrganization;
