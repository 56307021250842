/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { USA_STATES } from '../../utils/constant';
import { Checkbox, Select, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useMedplum } from '@medplum/react';
import { listingPage } from '../../utils/CustomAPI';

interface PatientDetailsProps {
  formData?: any;
  handleInputChange: (event: any) => void;
  handleOnSelect: (name: any, e: any) => void;
  handleCheckBoxChange: (event: any) => void;
  handleDateChange: (date: any) => void;
}
const PatientDetails: React.FC<PatientDetailsProps> = ({
  formData,
  handleInputChange,
  handleOnSelect,
  handleCheckBoxChange,
  handleDateChange,
}) => {
  const medplum = useMedplum();
  const [services, setServices] = useState([]);
  const [servicesResponse, setServicesResponse] = useState([]);
  const [providerGroup, setProviderGroup] = useState([]);
  const [provider, setProvider] = useState([]);

  useEffect(() => {
    getHealthService();
    getProvider();
  }, []);

  const getHealthService = () => {
    medplum
      .search('HealthcareService')
      .then((res: any) => {
        setServicesResponse(res?.entry);
        setServices(
          res?.entry
            .map((entry: any) => ({
              value: entry.resource?.name,
              label: entry?.resource?.name,
            }))
            .reduce((acc: any, current: any) => {
              const x = acc.find((item: any) => item.value === current.value);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, [])
        );
        // setProviderGroup(
        //   res?.entry.map((entry: any) => ({
        //     value: entry.resource?.providedBy.reference?.split('/')[1],
        //     label: entry.resource?.providedBy.display,
        //   }))
        // );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  
  const getProvider = () => {
    listingPage(medplum, 'get-practitioner-list', '20', '0')
      .then((res: any) => {
        setProvider(
          res?.data?.entry.map((entry: any) => ({
            value: entry.resource?.id,
            label: entry?.resource?.name[0]?.given[0].concat(' ', entry?.resource?.name[0]?.family),
          }))
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleServiceChange = (e: any) => {
    //filter provider based on selected service name
    const selectedService: any = servicesResponse.filter((service: any) => service.resource.name === e);

    setProviderGroup(
      selectedService.map((entry: any) => ({
        value: entry.resource?.providedBy.reference?.split('/')[1],
        label: entry.resource?.providedBy.display,
      }))
    );
  };

  const handleProviderChange = (e: any) => {
    medplum
      .search('PractitionerRole', {
        organization: `Organization/${e}`,
      })
      .then((res: any) => {
        console.log(res);
        setProvider(
          res?.entry.map((entry: any) => ({
            value: entry.resource?.practitioner.reference?.split('/')[1],
            label: entry?.resource?.practitioner.display,
          }))
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
    handleOnSelect('providerGroup', e);
  };

  return (
    <>
      <div className="tw-max-w-3xl tw-mt-5">
        <div className="tw-space-y-4">
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                First Name<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                placeholder="First Name"
                name="firstName"
                value={formData?.firstName}
                onChange={handleInputChange}
                required
              />
            </div>{' '}
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Last Name<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                placeholder="Last Name"
                name="lastName"
                value={formData?.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Service Category<span className="tw-text-red-500">*</span>
              </label>
              <Select
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                data={services}
                placeholder="Services"
                value={formData?.services}
                name="services"
                onChange={handleServiceChange}
                required
                searchable
              />
            </div>

            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Provider Group<span className="tw-text-red-500">*</span>
              </label>
              <Select
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                data={providerGroup}
                placeholder="Provider Group"
                value={formData?.providerGroup}
                name="providerGroup"
                onChange={handleProviderChange}
                required
                
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Provider<span className="tw-text-red-500">*</span>
              </label>
              <Select
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                data={provider}
                placeholder="Provider"
                value={formData?.provider}
                name="provider"
                onChange={(event) => handleOnSelect('provider', event)}
                required

              />
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Mobile<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                type="number"
                value={formData?.mobile}
                name="mobile"
                placeholder="Mobile Number"
                onChange={handleInputChange}
                maxLength={10}
                required

              />
            </div>
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Email<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                type="email"
                placeholder="Email"
                name="email"
                value={formData?.email}
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Date of Birth<span className="tw-text-red-500">*</span>
              </label>

              <DateInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                value={formData?.dob ? new Date(formData?.dob) : null}
                onChange={handleDateChange}
                placeholder="Date of Birth"
                valueFormat="YYYY-MM-DD"
                required

              />
            </div>
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Gender<span className="tw-text-red-500">*</span>
              </label>
              <Select
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                data={['Male', 'Female']}
                placeholder="Gender"
                value={formData?.gender}
                name="gender"
                onChange={(event) => handleOnSelect('gender', event)}
                required

              />
            </div>
          </div>
          <div style={{ marginTop: '1 rem' }}>
            <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">Address</label>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Street <span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="Street Address"
                  name="street"
                  value={formData?.street}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  City <span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="City"
                  name="city"
                  value={formData?.city}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  State <span className="tw-text-red-500">*</span>
                </label>
                <Select
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  data={USA_STATES}
                  placeholder="State"
                  value={formData?.state}
                  name="state"
                  searchable
                  onChange={(event) => handleOnSelect('state', event)}
                  required

                />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Zip Code <span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="Zip Code"
                  name="postalCode"
                  value={formData?.postalCode}
                  required
                  onChange={handleInputChange}
                  maxLength={6}
                />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Insurance Number</label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                placeholder="Insurance Number"
                name="insuranceNumber"
                value={formData?.insuranceNumber}
                onChange={handleInputChange}
                
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Create Account</label>

              <Checkbox
                size="sm"
                label="Yes"
                name="createAccount"
                checked={formData?.userId ? true : formData?.createAccount}
                onChange={handleCheckBoxChange}
                disabled={formData?.userId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetails;
