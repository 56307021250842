/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Loader, Modal, Select, rem } from '@mantine/core';
import { IconCalendarMonth, IconChevronDown, IconFlag, IconSearch, IconUser } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { DateInput } from '@mantine/dates';
import Slots from '../../components/Slots';
import { useMedplum } from '@medplum/react';
import { useNavigate } from 'react-router-dom';
import { AppointmentResource } from './AppointmentResource';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import { bookAppointment, cancelAppointment, updateAppointment } from '../../utils/CustomAPI';
import {format} from 'date-fns';
import ConfirmBox from '../../components/ConfirmBox';

interface AppointmentsProps {
  opened: boolean;
  close: () => void;
  appointmentId: string;
}

interface formData {
    patient: string;
    patientName: string;
    service: string;
    serviceName: string;
    practitionerName: string;
    practitioner: string;
    startDate: string;
    slotEndDate: string;
    slotStartDate: string;
    slots: string;
    appointmentType: string;
    note: string;
}

const Appointments: React.FC<AppointmentsProps> = (props: AppointmentsProps) => {
    const medplum = useMedplum();
    const navigate = useNavigate();
    const defaultFields: formData = {
        patient: '',
        patientName: '',
        service: '',
        serviceName: '',
        practitionerName: '',
        practitioner: '',
        startDate: '',
        slotStartDate: '',
        slotEndDate: '',
        slots: '',
        appointmentType: '',
        note: ''
    }
    const [patients, setPatients] = useState<any[]>([]);
    const [services, setServices] = useState<any[]>([]);
    const [servicesResponse, setServicesResponse] = useState<any[]>([]);
    const [provider, setProvider] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState<formData>(defaultFields);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);
    const [appStartDate, setAppStartDate] = useState<string>('');
    const [appEndDate, setAppEndDate] = useState<string>('');
    let serviceId: string = '';

    // load patients list
    const loadPatients = useCallback(async () => {
        try {
            const res: any = await medplum.search('Patient');
            setPatients(res?.entry
                .map((entry: any) => ({
                value: entry.resource?.id,
                label: entry?.resource?.name[0]?.given[0] + ' ' + entry?.resource?.name[0]?.family,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    }, []);

    // load services list
    const loadServices = useCallback(async () => {
        try {
            const res: any = await medplum.search('HealthcareService');
            setServicesResponse(res?.entry);
            setServices(res?.entry
                .map((entry: any) => ({
                value: entry.resource?.id,
                label: entry?.resource?.name,
                }))
            );
            if(props.appointmentId) {
              getProvider(serviceId, res?.entry);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    //get selected appointments
    const getAppointments = useCallback(async () => {
        try {
            const res: any = await medplum.readResource('Appointment', props?.appointmentId);
            serviceId = res.participant[1]?.actor?.reference?.split('/')[1];
            setFormData(prevData => ({
                ...prevData,
                patient: res?.participant?.[0]?.actor?.reference?.split('/')[1],
                patientName: res?.participant?.[0]?.actor?.display,
                service: res?.participant?.[1]?.actor?.reference?.split('/')[1],
                practitioner: res?.participant?.[2]?.actor?.reference?.split('/')[1],
                practitionerName: res?.participant?.[2]?.actor?.display,
                startDate: res?.start,
                slotStartDate: res?.start,
                slotEndDate: res?.end,
                slots: res?.slot?.[0]?.reference?.split('/')[1],
                appointmentType: res?.appointmentType?.coding?.[0]?.code,
                note: res?.description
            }));
            setAppStartDate(res.start);
            setAppEndDate(res.end);
        } catch (error) {
            console.log(error);
        }
    }, []);

    // load patients and services
    useEffect(() => {
        setIsLoading(false);
        Promise.all([loadPatients(), loadServices(), props?.appointmentId && getAppointments()])
            .then(() => setIsLoading(false))
            .catch((error) => console.log(error));
    }, [loadPatients, loadServices]);

    // handle select change and get the practitioner based on the service
    const onChangeService = (value: any, e: any) => {
        setFormData({
            ...formData,
            service: value,
            serviceName: e?.label
        });
        getProvider(value);
    }

    const getProvider =  (value: any, serviceList?: any) => {
      const updatedServicesResponse = serviceList || servicesResponse;
      const service = updatedServicesResponse.find((service: any) => service.resource.id === value);
      medplum.search(
        'PractitionerRole',
        {
          'organization': `Organization/${service?.resource?.providedBy?.reference.split('/')[1]}`,
        }
      ).then((res: any) => {
        setProvider(
            res?.entry.map((entry: any) => ({
              value: entry.resource?.practitioner.reference?.split('/')[1],
              label: entry?.resource?.practitioner.display,
            }))
          );
      }).catch((error: any) => {
        console.log('error', error);
      });
    }

    // handle select change
    const handleSelect = (name: string, event: any, e?: any) => {
        setFormData({
            ...formData,
            [name]: event,
            patientName: name === 'patient' ? e?.label : formData?.patientName,
            practitionerName: name === 'practitioner' ? e?.label : formData.practitionerName
        });
    };

    // handle input change
    const handleInputChange = useCallback((event: any) => {
      const { name, value } = event.target;
  
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }, []);

    // get slots from the slots component
    const onSelectSlot = (slot: any) => {
        setFormData({
            ...formData,
            slots: slot.id,
            slotStartDate: slot?.startTime,
            slotEndDate: slot?.endTime
        });
    }

    // create appointment
    const createAppointment =  () => {
      if(formData.slots === '') {
        setLoading(false);
        setError(true);
        return;
      }
      setLoading(true);
      setError(false);
      const appointment = AppointmentResource(formData, props.appointmentId);
      if (props.appointmentId) {
        updateAppointment(medplum, appointment).then(() => {
          setLoading(false);
          showNotification({ color: 'green', message: 'Appointment updated successfully' });
          props.close();
          navigate('/Appointment');
        }).catch((error: any) => {
            console.log(error);
            setLoading(false);
            showNotification({ color: 'red', message: normalizeErrorString(error) });
        });
      } else {
        bookAppointment(medplum, appointment).then(() => {
          setLoading(false);
          showNotification({ color: 'green', message: 'Appointment booked successfully' });
          props.close();
          navigate('/Appointment');
        }).catch((error: any) => {
            console.log(error);
            setLoading(false);
            showNotification({ color: 'red', message: normalizeErrorString(error) });
        });
      }
    }

    // cancel appointment
    const onCancleAppointment = (reason: string) => {
      const cancellation_reason = {
        coding: [
          {
            code: reason,
            display: reason
          }
        ]
      }
      cancelAppointment(medplum, { appointment_id: props.appointmentId, cancellation_reason }).then(() => {
        showNotification({ color: 'green', message: 'Appointment canceled successfully' });
        setConfirmBox(false);
        props.close();
        navigate('/Appointment');
      }).catch((error: any) => {
        console.log(error);
        showNotification({ color: 'red', message: normalizeErrorString(error) });
      });
    }

    // In your component
    if (isLoading) {
        return <div>Loading...</div>; // Or some loading spinner
    }
  return (
    <>
      <Modal.Root opened={props.opened} onClose={props.close} size="lg" className="appointment">
        <Modal.Overlay />
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (createAppointment) {
              createAppointment();
            }
          }}
        >
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                <div className="appointment_title">
                  <IconFlag style={{ transform: 'rotate(345deg)' }} />
                </div>
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <h3 className="tw-text-lg tw-font-semibold tw-text-[#101828]">{props.appointmentId ? 'Update' : 'New'} appointment</h3>
              <p className="tw-text-sm tw-leading-relaxed tw-text-[#475467]">Enter details ro create new appointment.</p>
              <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
                <div className="tw-mt-6">
                  {props.appointmentId ? (
                    <>
                      <div className="tw-flex tw-items-center">
                          <div className='tw-flex tw-items-center tw-justify-center tw-bg-[#F2F4F7] tw-border-[1px] tw-border-[#EAECF0] tw-rounded-full tw-w-[50px] tw-h-[50px]'>
                            <IconUser size={25} color='#475467' />
                          </div>
                          <div className='tw-ml-5'>
                            <p className='tw-text-[#101828] tw-font-semibold'>{formData.patientName}</p>
                            <p className=''>
                              {format(new Date(appStartDate), 'dddd, MM, YYYY, hh:mm a')} - {format(new Date(appEndDate), 'hh:mm a')}
                            </p>
                          </div>
                      </div>
                      <hr className='tw-mt-5'/>
                    </>
                  ) : (
                    <div className="tw-flex tw-items-end">
                      <div className="tw-w-5/6">
                        <Select
                          label={<span className="tw-text-[#475467]">Pateint</span>}
                          placeholder="Select patient"
                          data={patients}
                          searchable
                          required
                          name="patient"
                          value={formData.patient}
                          leftSection={<IconSearch style={{ width: rem(18), height: rem(18) }} />}
                          rightSection={<IconChevronDown size="16px" />}
                          onChange={(event, e) => handleSelect('patient', event, e)}
                        />
                      </div>
                      <div className="tw-w-1/10">
                        <button className="tw-bg-[#4DB5BA] tw-text-white tw-ml-3 tw-rounded-lg tw-p-[0.65rem] tw-ml-2 tw-font-medium" onClick={() => navigate('/Patient/new')}>
                          Add New
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                    <div className="tw-w-[48%]">
                      <Select
                        label={<span className="tw-text-[#475467]">Service</span>}
                        placeholder="Select Service"
                        data={services}
                        value={formData.service}
                        name='service'
                        rightSection={<IconChevronDown size="16px" />}
                        onChange={(event, e) => onChangeService(event, e)}
                        required
                      />
                    </div>
                    <div className="tw-w-[48%]">
                      <Select
                        label={<span className="tw-text-[#475467]">Practitioner</span>}
                        placeholder="Select Practitioner"
                        data={provider}
                        name="practitioner"
                        value={formData.practitioner}
                        rightSection={<IconChevronDown size="16px" />}
                        onChange={(event, e) => handleSelect('practitioner', event, e)}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
                    <div className="tw-w-[48%]">
                      <DateInput
                        placeholder="Start Date"
                        valueFormat="YYYY-MM-DD"
                        name="startDate"
                        label={<span className="tw-text-[#475467]">Date</span>}
                        rightSection={<IconCalendarMonth size="16px" />}
                        required
                        value={formData.startDate ? new Date(formData.startDate) : null}
                        onChange={(event) => handleSelect('startDate', event)}
                      />
                    </div>
                    <div className="tw-w-[48%]">
                      <Select
                        label={<span className="tw-text-[#475467]">Appointment Type</span>}
                        placeholder="Select Appointment Type"
                        data={[
                          { value: 'Routine', label: 'Routine' },
                          { value: 'Followup', label: 'Followup' },
                        ]}
                        name="appointmentType"
                        value={formData.appointmentType}
                        rightSection={<IconChevronDown size="16px" />}
                        onChange={(event, e) => handleSelect('appointmentType', event, e)}
                      />
                    </div>
                  </div>
                  <div className="tw-mt-5">
                    <Slots onSelectSlot={onSelectSlot} serviceId={formData.service} startDate={formData.startDate} slotError={error}/>
                  </div>
                  <div className="tw-mt-5">
                    <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-[#475467]">Note</label>
                    <textarea
                      className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-rounded-lg tw-border tw-border-gray-300"
                      placeholder="Note..."
                      name="note"
                      onChange={handleInputChange}
                      value={formData.note}
                    ></textarea>
                  </div>
                </div>
            </Modal.Body>
            <hr className="tw-mt-6 tw-bg-[#EEF1F6]" />
            <div className='tw-flex tw-p-4' style={{
                  justifyContent: `${props.appointmentId ? 'center' : 'end'}`,
              }}>
              {props.appointmentId && (
                <button type="button" className="tw-w-1/2 tw-text-[red] tw-bg-white tw-font-semibold tw-rounded-lg tw-text-[15px] tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-border tw-border-[red]" onClick={() => setConfirmBox(true)}>Cancel Appointment</button>
              )}
              <button type="submit" className="tw-w-1/2 tw-text-white tw-bg-[#3CA5A9] tw-font-semibold tw-rounded-lg tw-text-[15px] tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2">
                {loading ? (
                <>
                  {props.appointmentId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : props.appointmentId ? (
                'Update Appointment'
              ) : (
                'Create Appointment'
              )}
              </button>
            </div>
          </Modal.Content>
        </form>
      </Modal.Root>
      {confirmBox && (
        <ConfirmBox opened={confirmBox} close={() => setConfirmBox(false)} onConfirm={(data) => onCancleAppointment(data)}/>
      )}
    </>
  );
};

export default Appointments;
