import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { Checkbox } from '@mantine/core';

interface OrganizationDetailsProps {
  formData: any;
  handleInputChange: (event: any) => void;
  handleCheckBoxChange: (event: any) => void;
}

const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  formData,
  handleInputChange,
  handleCheckBoxChange,
}) => {
  return (
    <div className="tw-max-w-3xl tw-mt-5">
      <h2 className="tw-text-2xl tw-font-medium tw-text-gray-800 tw-mb-6">Brand Details</h2>
      <div className="tw-space-y-4">
        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="name">
              Brand Name<span className="tw-text-red-500">*</span>
            </label>
            <input
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Brand Name"
              required
            />
          </div>
          <div className="tw-w-full md:tw-w-1/2 tw-px-2">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="mobile">
              Mobile<span className="tw-text-red-500">*</span>
            </label>
            <input
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              type="tel"
              id="mobile"
              name="brandMobile"
              value={formData.brandMobile}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              required
            />
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="email">
              Email<span className="tw-text-red-500">*</span>
            </label>
            <input
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              type="email"
              id="email"
              name="brandEmail"
              value={formData.brandEmail}
              onChange={handleInputChange}
              placeholder="Email Address"
              required
            />
          </div>
          <div className="tw-w-full md:tw-w-1/2 tw-px-2">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="status">
              Status<span className="tw-text-red-500">*</span>
            </label>
            <div className="tw-relative">
              <select
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-appearance-none tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                required
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              <IconChevronDown
                className="tw-absolute tw-right-3 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-text-gray-400"
                size={14}
              />
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          <div className="tw-w-1/3 tw-px-2">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-3">Super Bill</label>
            <Checkbox
              labelPosition="right"
              label="Yes"
              id="superBill"
              name="superBill"
              checked={formData.superBill}
              onChange={handleCheckBoxChange}
              size="sm"
            />
          </div>
          <div className="tw-w-1/3 tw-px-2">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-3">State Match</label>
            <div>
              <Checkbox
                labelPosition="right"
                label="Yes"
                id="stateMatch"
                name="stateMatch"
                checked={formData.stateMatch}
                onChange={handleCheckBoxChange}
                size="sm"
              />
            </div>
          </div>
          <div className="tw-w-1/3 tw-px-2">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-3">Show Summary</label>
            <Checkbox
              labelPosition="right"
              label="Yes"
              id="showSummary"
              name="showSummary"
              size="sm"
              // Remaining To Implement
            />
          </div>
        </div>

        <div className="tw-mb-4">
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="summary">
            Summary
          </label>
          <textarea
            className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
            id="summary"
            name="summary"
            value={formData.summary}
            onChange={handleInputChange}
            placeholder="Summary"
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetails;
