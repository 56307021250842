/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Grid, Button, Card, Box, Divider, Text, Loader } from '@mantine/core';
import { checkInputValue } from '../../utils/util';
import ProviderGroupDetails from './ProviderGroupDetails';
import AdminDetails from '../AdminDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { normalizeErrorString } from '@medplum/core';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { Resource } from '@medplum/fhirtypes';
import { checkUserExists, createProviderGroup, createUpdateProviderGroup } from '../../utils/CustomAPI';
import { createOrganization } from './OrganizationResources';
import TitleComponent from '../../components/TitleComponent';

const ProviderGroup = () => {
  const providerId = useParams().id;
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const { adminData, brands } = location?.state || {};
  const [formData, setFormData] = useState<any>({
    name: '',
    providerMobile: '',
    providerEmail: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    status: 'true',
    npi: '',
    service: '',
    adminFields: [],
  });
  const [error, setError] = useState(false);
  const [brand, setBrand] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  let notifications = true;

  const getDefaultFormData = () => ({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const [fields, setFields] = useState<any>(
    adminData?.length > 0
      ? adminData.map((data: any, index: number) => ({ fieldId: index + 1, formData: data }))
      : [{ fieldId: 1, formData: getDefaultFormData() }]
  );

  // Get brands list and set in the state
  useEffect(() => {
    if (providerId) {
      medplum
        .readResource('Organization', providerId)
        .then((res: any) => {
          const telecomMap = res.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
          const extensionMap = res.extension.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.url]: curr.valueString }),
            {}
          );

          setFormData((prevData: any) => ({
            ...prevData,
            name: res.name,
            providerMobile: telecomMap['phone'] || '',
            providerEmail: telecomMap['email'] || '',
            status: res.active ? 'true' : 'false',
            street: res.address[0]?.line[0] || '',
            city: res.address[0]?.city || '',
            state: res.address[0]?.state || '',
            postalCode: res.address[0]?.postalCode || '',
            service: extensionMap['http://providergroup-services'] || '',
            npi: extensionMap['http://providergroup-npi'] || '',
          }));
        })
        .catch((error: any) => {
          console.error('Error reading resource:', error);
          showNotification({ color: 'red', message: normalizeErrorString(error) });
        });
      setBrand(brands?.map((item: any) => item?.id));
    }
  }, []);

  // Add admin details in the form
  const addAdminDetails = () => {
    setFields((prevFields: any) => [
      ...prevFields,
      {
        fieldId: prevFields.length > 0 ? prevFields[prevFields.length - 1].fieldId + 1 : 1,
        formData: getDefaultFormData(),
      },
    ]);
  };

  // Set admin fields in form data
  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      adminFields: fields,
    }));
  }, [fields]);

  // Remove admin details from the form
  const removeAdminDetails = (id: any) => {
    setFields((prevFields: any[]) => prevFields.filter((field) => field.fieldId !== id));
  };

  // Handle input change value and set form data
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  };

  // Handle select change value and set form data
  const handleOnSelect = (name: any, e: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e,
    }));
  };

  // Handle admin input change value and set form data
  const handleAdminInputChange = (e: any, fieldId: any) => {
    const { name, value } = e.target;
    setFields((prevFields: any) =>
      prevFields.map((field: { fieldId: number; formData: any }) =>
        field.fieldId === fieldId ? { ...field, formData: { ...field.formData, [name]: value } } : field
      )
    );
  };

  // Handle multi select change value and set form data
  const handleMultiSelectChange = (value: any) => {
    setBrand(value);
  };

  // Handle submit form data
  const handleSubmit = async() => {
    // Create organization resource
    

    try {
      const response = await checkUserExists(medplum, { email: formData.providerEmail });
      if (response?.exists) {
        showNotification({ color: 'red', message: 'Email already exists' });
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showNotification({ color: 'red', message: 'Error checking user existence' });
      return;
    }


    const organization = createOrganization(formData, providerId);

    const isError = formData.adminFields.some((field: any) => field.password !== field.confirm_password);

    if (isError) {
      setError(true);
    }

    setIsLoading(true);
    createUpdateProviderGroup(medplum, organization)
      .then((resource) => {
        providerAdminCreate(resource);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  // Create and update provider group admin
  const providerAdminCreate = async (resource: Resource) => {
    for (const data of formData?.adminFields) {
      const formDataArray = {
        ...data.formData,
        providergroup_id: resource.id,
        brand_ids: brand,
        profile_id: data.formData.profile_id,
        id: data.formData.id,
      };

      
      try {
        await createProviderGroup(medplum, formDataArray);
        setIsLoading(false);
        if (notifications) {
          showNotification({
            color: 'green',
            message: `${providerId ? 'Provider Group updated successfully' : 'Provider Group created successfully'}`,
          });
          notifications = false;
        }
        navigate(`/ProviderGroup`);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      }
    }
  };
  return (
    <>
      <Box px="lg" pt="sm" mt={65} mb={20}>
        <TitleComponent title={`${providerId ? 'Update' : 'Add new'} Provider Groups`} />
      </Box>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="org-form card-bg">
          <Card shadow="sm" radius="md">
            <ProviderGroupDetails
              formData={formData}
              handleInputChange={handleInputChange}
              handleMultiSelectChange={handleMultiSelectChange}
              brands={brands}
              handleSelect={handleOnSelect}
            />
            <Divider my={20} />
            <Text style={{ fontSize: '18px', color: '#272D41', fontWeight: '500' }} className="tw-my-2">
              Admin Details{' '}
            </Text>
            {fields?.map((detail: { fieldId: React.Key | null | undefined }, index: number) => (
              <div key={detail.fieldId}>
                {index > 0 && (
                  <>
                    <Divider pb={20} />
                    <button
                      type="button"
                      style={{
                        backgroundColor: '#FFEAEA',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        margin: '0 8px',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => removeAdminDetails(detail.fieldId)}
                    >
                      <IconMinus size={20} style={{ margin: '5px' }} />
                    </button>
                  </>
                )}
                <AdminDetails
                  formData={formData}
                  fields={detail}
                  error={error}
                  handleInputChange={(e: any) => handleAdminInputChange(e, detail.fieldId)}
                />
              </div>
            ))}
            <Button
              style={{
                background: 'white',
                border: '1px solid #D0D5DD',
                padding: '8px, 12px, 8px, 12px',
                fontWeight: 600,
                float: 'right',
                '&:hover': {
                  background: '#F9FAFB',
                },
                fontSize: '14px',
                color: '#121926',
                width: 'fit-content',
                margin: '15px 0px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              type="button"
              onClick={addAdminDetails}
            >
              <IconPlus size={20} className="tw-mr-2" />
              Add new admin
            </Button>
          </Card>
        </Box>
        {/* Buttons */}
        <Grid px="lg" className="org-form card-bg">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/ProviderGroup`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg tw-p-[12px, 18px, 12px, 18px]"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="act-btn tw-border tw-rounded-lg tw-p-[12px, 18px, 12px, 18px]"
              style={{ marginTop: '0' }}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  {providerId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : providerId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default ProviderGroup;
