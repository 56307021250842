import { Paper } from '@mantine/core';
import { formatSearchQuery, parseSearchRequest, SearchRequest } from '@medplum/core';
import { Loading, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from '../HomePage.module.css';
import { addSearchValues, RESOURCE_TYPE_CREATION_PATHS, saveLastSearch } from '../HomePage.utils';
import { MemoizedSearchControl } from './SearchControl';
import Appointments from './Appointments/Appointments';

// Brands, Listing Menu, Providers, Default Page
// This page is Dynamic for all tabs listing table

export function ListingMenu(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<SearchRequest>();
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  
  useEffect(() => {
    // Parse the search from the URL
    const parsedSearch = parseSearchRequest(location.pathname + location.search);
    // Fill in the search with default values
    const populatedSearch = addSearchValues(parsedSearch, medplum.getUserConfiguration());
    if (
      location.pathname === `/${populatedSearch.resourceType}` &&
      location.search === formatSearchQuery(populatedSearch)
    ) {
      // If the URL matches the parsed search, then save it and execute it

      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);

      
    } else {
      // Otherwise, navigate to the desired URL
      navigate(`/${populatedSearch.resourceType}${formatSearchQuery(populatedSearch)}`);
    }
  }, [medplum, navigate, location]);
  
  if (!search?.resourceType || !search.fields || search.fields.length === 0) {
    return <Loading />;
  }

  return (
    <Paper  bg={'var(--background-main)'} className={classes.paper}>
      <MemoizedSearchControl
        checkboxesEnabled={true}
        search={search}
        onClick={(e) => navigate(`/${e.resource.resourceType}/${e.resource.id}`)}
        onAuxClick={(e) => window.open(`/${e.resource.resourceType}/${e.resource.id}`, '_blank')}
        onChange={(e) => {
          navigate(`/${search.resourceType}${formatSearchQuery(e.definition)}`);
        }}
        onNew={() => {
          if(location.pathname === '/ProviderGroup') {
            search.resourceType = 'ProviderGroup';
          } else if(location.pathname === '/Staff') {
            search.resourceType = 'Staff';
          }
          if(location.pathname === '/Appointment') {
            setIsAppointment(true);
          } else {
            navigate(RESOURCE_TYPE_CREATION_PATHS[search.resourceType] ?? `/${search.resourceType}/new`);
          }
        }}
      />
      {isAppointment && (
        <Appointments opened={isAppointment} close={() => setIsAppointment(false)} appointmentId=''/>
      )}
    </Paper>
  );
}
