/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from './axios';
import { MedplumClient } from '@medplum/core';

export const createBrandAdmin = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-brand-admin`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const checkUserExists = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/check-user-exists`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdateBrand = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-brand`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createProviderGroup = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-providergroup-admin`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdateProviderGroup = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-providergroup`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createProvider = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-practitioner`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdateStaff = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-providergroup-staff`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdateService = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-healthcareservice`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdatePatientAccount = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient-account`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdatePatient = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createUpdatePatientResource = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patientdata-resource`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const uploadPatient = async (medplum: MedplumClient, file: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const formData = new FormData();
  formData.append('file', file);
  formData.append('provider_group_id', payload.providerGroup);
  formData.append('practitioner_id', payload.provider);
  formData.append('createAccount', payload.createAccount);

  const response = await axios.post(`/healthside/import-patient-csv`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const listingPage = async (medplum: MedplumClient, apiUrl: string, count?: any, offset?: any, search?: any) => {
  const token = await medplum.getAccessToken();
  let queryParameters = '';
  let sortingParameters = '';
  // Loop through the filters array to build the query string
  if (search?.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }
  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }
  if (apiUrl === 'get-patient-list') {
    const response = await axios.get(
      `/healthside/${apiUrl}?_count=${count}&_offset=${offset}${sortingParameters}&_total=accurate${queryParameters}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  } else {
    const response = await axios.get(
      `/healthside/${apiUrl}?_count=${count}&_offset=${offset}${sortingParameters}&_total=accurate${queryParameters}&active=true`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  }
};

export const getSlots = async (medplum: MedplumClient, serviceId: string, startDate: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(
    `/fhir/R4/Slot?_count=20&_offset=0&_sort=start&_total=accurate&schedule.actor=HealthcareService/${serviceId}&status=free&&start=ge${startDate}T00:00:00Z&start=lt${startDate}T23:59:00Z`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response;
};

export const bookAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/book-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const updateAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/update-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const cancelAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/cancel-appointment`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const graphqlCall = async (medplum: any, query: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
