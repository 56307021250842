/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Grid } from '@mantine/core';
import React from 'react';
import BreadcrumbMain from './BreadcrumbMain';

interface TitleProps {
  title: string;
}

const TitleComponent: React.FC<TitleProps> = ({ title }) => {
  return (
    <Card display="flex" p='0' pt={10} className="tw-bg-transparent">
      <Grid>
        <Grid.Col  className="titlebar-content">
          {<BreadcrumbMain />}
        </Grid.Col>
        <Grid.Col  className="titlebar-content" >
            <div className="title tw-font-semibold tw-text-[#101828] tw-text-[25px]" >
              {title}
            </div>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default TitleComponent;
