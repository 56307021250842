/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Practitioner } from "@medplum/fhirtypes";


export const createStaffResource = (formData: any, staffId: string | undefined) => {
    const practitioner: Practitioner = {
        resourceType: 'Practitioner',
        name: [
            {
                given: [formData.firstName],
                family: formData.lastName,
            },
        ],
        telecom: [
            {
                system: 'phone',
                value: formData.providerMobile,
            },
            {
                system: 'email',
                value: formData.providerEmail,
            },
        ],
        active: formData.status === 'true'
    };
    if(staffId) {
        practitioner.id = staffId;
    }
    return practitioner;
}