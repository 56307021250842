/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, Card, Box, Divider, Loader } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProviderDetails from './ProviderDetails';
import { createPractitioner } from './PractitionerResources';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import CredentialsDetails from './CredentialsDetails';
import { checkUserExists, createProvider } from '../../utils/CustomAPI';
import TitleComponent from '../../components/TitleComponent';

const Provider = () => {
  const medplum = useMedplum();
  const providerId = useParams().id;
  const location = useLocation();
  const { userId, role } = location?.state || {};
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [error, setError] = useState(false);
  const providerDetailsRef = useRef<{ getData: () => any }>(null);
  const credentialsDetailsRef = useRef<{ getData: () => any }>(null);

  // Fetch organization data and set form data
  useEffect(() => {
    if (providerId) {
      medplum
        .readResource('Practitioner', providerId)
        .then((res: any) => {
          const telecomMap = res?.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
          const extensionMap = res?.extension.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.url]: curr.valueString }),
            {}
          );

          setFormData((prevData: any) => ({
            ...prevData,
            firstName: res.name[0].given[0],
            lastName: res.name[0].family,
            providerMobile: telecomMap['phone'] || '',
            providerEmail: telecomMap['email'] || '',
            status: res.active ? 'true' : 'false',
            street: res?.address?.[0].line?.[0],
            suiteApt: res?.address?.[0].line?.[1],
            city: res?.address?.[0].city,
            state: res?.address?.[0].state,
            postalCode: res?.address?.[0].postalCode,
            imageUrl: res?.photo?.[0].url,
            imageTitle: res?.photo?.[0].title,
            npi: res?.identifier?.[0].value,
            title: res?.qualification?.[0].code.coding[0].code,
            displayName: extensionMap['http://display-name'] || '',
            LicenseFields: extensionMap['http://state-licenses']
              ? JSON.parse(extensionMap['http://state-licenses'])
              : [],
            DEALicenseFields: extensionMap['http://DEA-licenses']
              ? JSON.parse(extensionMap['http://DEA-licenses'])
              : [],
            providerGroupIds: role?.map((item: any) => item?.organizationId),
            timezone: role?.[0].timezone,
            days: role?.[0].daysOfWeek,
            availableTime: role?.[0].availableTime,
            servicesId: role?.[0].serviceId,
          }));
        })
        .catch((error: any) => {
          console.error('Error reading resource:', error);
          showNotification({ color: 'red', message: 'Failed' });
        });
    }
  }, [providerId, medplum]);

  // Handle submit form data
  const handleSubmit = async() => {
    console.log("FormData ###",formData);



    //create practitioner resource
    if (providerDetailsRef.current) {
      const data = providerDetailsRef.current.getData();
      const credentialsData = credentialsDetailsRef.current?.getData();
      const practitioner = createPractitioner(data, providerId, credentialsData);
      if (data.password !== data.confirm_password) {
        setError(true);
        return;
      }
      if (!data?.providerGroupIds) {
        showNotification({ color: 'red', message: 'Please select Provider Group' });
        return;
      }
      setIsLoading(true);
      if (providerId) {
        //update practitioner
        medplum
          .updateResource(practitioner)
          .then((res) => {
            //create provider
            providerCreate(res, data, credentialsData);
          })
          .catch((err) => {
            setIsLoading(false);
            showNotification({ color: 'red', message: normalizeErrorString(err) });
          });
      } else {
        providerCreate({}, data, credentialsData);
      }
    }
  };

  //create provider and update
  const providerCreate = async (res: any, data: any, credentialsData: any) => {
    const payload = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data?.providerEmail,
      phone: data?.providerMobile,
      provider_group_ids: data?.providerGroupIds,
      service_id: data?.servicesId,
      password: data?.password,
      timezone: credentialsData?.timezone,
      available_time: credentialsData?.AvailableTime.map((item: any) => item.formData),
      id: userId,
      profile_id: res.id,
    };

    console.log("Data ####",data)


    try {
      const response = await checkUserExists(medplum, { email: data.providerEmail });
      if (response?.exists) {
        showNotification({ color: 'red', message: 'Email already exists' });
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showNotification({ color: 'red', message: 'Error checking user existence' });
      return;
    }

    createProvider(medplum, payload)
      .then((res) => {
        if(providerDetailsRef.current && !providerId) {
          const data = providerDetailsRef.current.getData();
          const credentialsData = credentialsDetailsRef.current?.getData();
          const practitioner = createPractitioner(data, res.profile_id, credentialsData);
          medplum
          .updateResource(practitioner)
          .then(() => {
            setIsLoading(false);
            showNotification({
              color: 'green',
              message: `${providerId ? 'Provider updated successfully' : 'Provider created successfully'}`,
            });
            navigate(`/Practitioner`);
          })
          .catch((err) => {
            setIsLoading(false);
            showNotification({ color: 'red', message: normalizeErrorString(err) });
          });
        } else {
          setIsLoading(false);
          showNotification({
            color: 'green',
            message: `${providerId ? 'Provider updated successfully' : 'Provider created successfully'}`,
          });
          navigate(`/Practitioner`);
        }
      })
      .catch((err: unknown) => {
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  return (
    <>
      {' '}
      <Box px="lg" pt="sm" mt={65} mb={20}>
        <TitleComponent title={`${providerId ? 'Update' : 'Add new'} Provider`} />
      </Box>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="card-bg">
          <Card shadow="sm" radius="lg">
            <ProviderDetails formData={formData} error={error} ref={providerDetailsRef} />

            <Divider my={20} />

            <CredentialsDetails updatedFormData={formData} ref={credentialsDetailsRef} />
          </Card>
        </Box>

        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/Practitioner`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }} disabled={isLoading}>
              {isLoading ? (
                <>
                  {providerId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : providerId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default Provider;
