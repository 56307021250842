/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { PasswordInput, TextInput } from '@mantine/core';

interface AdminDetailsProps {
  formData: any;
  error: boolean;
  fields?: any;
  handleInputChange: (event: any, index?: number) => void;
}

const AdminDetails: React.FC<AdminDetailsProps> = ({ formData, handleInputChange, error, fields }) => {
  const [passowrdError, setPassowrdError] = useState(error || false);

  const validatePasswords = () => {
    const password = fields ? fields.formData.password : formData.password;
    const confirm_password = fields ? fields.formData.confirm_password : formData.confirm_password;

    if (password !== confirm_password) {
      setPassowrdError(true);
      return false;
    } else {
      setPassowrdError(false);
      return true;
    }
  };

  useEffect(() => {
    if (
      (formData.password && formData.confirm_password) ||
      (fields?.formData?.password && fields?.formData?.confirm_password)
    ) {
      validatePasswords();
    }
  }, [formData]);

  return (
    <>
      <div className="tw-max-w-3xl tw-mt-5">
        <div className="tw-space-y-4">
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="name">
                First Name<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                value={formData.first_name || fields?.formData?.first_name}
                name="first_name"
                placeholder="First Name"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="tw-w-full md:tw-w-1/2 tw-px-2">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="mobile">
                Last Name<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                value={formData.last_name || fields?.formData?.last_name}
                name="last_name"
                placeholder="Last Name"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="mobile">
                Mobile<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                value={formData.phone || fields?.formData?.phone}
                type="number"
                name="phone"
                placeholder="Mobile Number"
                onChange={handleInputChange}
                maxLength={10}
                required

              />
            </div>

            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="email">
                Email<span className="tw-text-red-500">*</span>
              </label>
              <TextInput
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                value={formData.email || fields?.formData?.email}
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Password
                <span className="tw-text-red-500">*</span>
              </label>
              <PasswordInput
                value={formData.password}
                name="password"
                placeholder="Password"
                onChange={handleInputChange}
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                required
              />
            </div>

            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 tw-py-2 md:tw-mb-0 ">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Confirm Password
                <span className="tw-text-red-500">*</span>
              </label>
              <PasswordInput
                value={formData.confirm_password}
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleInputChange}
                styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                required
                error={passowrdError ? 'Password does not match' : null}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDetails;
