import { Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

interface BreadcrumbProps {}

const BreadcrumbMain: React.FC<BreadcrumbProps> = () => {
  const id = useParams().id!;
  const currentURL: string = window.location.pathname;

  const breadcrumbMapping: { label: string; url: string; redirectTo: string }[] = [
    { label: 'Home', url: '/', redirectTo: '/' },
    { label: 'Patient Details', url: `${'/patient-dashboard/' + id}`, redirectTo: '' },
    {
      label: 'Patient Details',
      url: `${'/Patient/patient-dashboard/' + id}`,
      redirectTo: `${'/Patient/patient-dashboard/' + id}`,
    },
    {
      label: 'Consultations',
      url: `${'/Patient/clinical-note/' + id}`,
      redirectTo: '',
    },
    {
      label: 'Providers',
      url: '/Practitioner',
      redirectTo: '/Practitioner',
    },
    {
      label: 'Brands',
      url: '/Organization',
      redirectTo: '/Organization',
    },
    {
      label: 'Create new brand',
      url: '/Organization/new',
      redirectTo: '',
    },
    {
      label: 'Appointment',
      url: '/Appointment',
      redirectTo: '',
    },
    { label: 'Provider Groups', url: '/ProviderGroup', redirectTo: '/ProviderGroup' },
    { label: 'Add new provider group', url: '/ProviderGroup/new', redirectTo: '/ProviderGroup' },
    { label: 'Staff', url: '/Staff', redirectTo: '/Staff' },
    { label: 'Add new Staff', url: '/Staff/new', redirectTo: '/Staff/new' },
    { label: 'Services', url: '/HealthcareService', redirectTo: '/HealthcareService' },
    { label: 'Add new services', url: '/HealthcareService/new', redirectTo: '/HealthcareService/new' },
    { label: 'Patients', url: '/Patient', redirectTo: '/Patient' },
    { label: 'Add new patient', url: '/Patient/new', redirectTo: '/Patient/new' },
    { label: 'Upload Patient', url: '/Patient/UploadCSV', redirectTo: '/Patient/UploadCSV' },
  ];

  function generateBreadcrumbs(url: string): { label: string; url: string; redirectTo: string }[] {
    const segments: string[] = url.split('/').filter((segment) => segment !== '');
    const breadcrumbs: { label: any; url: string; redirectTo: string }[] = [
      {
        label: <img src="/img/icons/IconHome.svg" className="breadcrumb-IconHome" />,
        url: '/dashboard',
        redirectTo: '/dashboard',
      },
    ];

    let currentPath = '';
    for (const segment of segments) {
      currentPath += `/${segment}`;
      const breadcrumb = breadcrumbMapping.find((item) => item.url === currentPath);
      if (breadcrumb) {
        breadcrumbs.push(breadcrumb);
      }
    }

    return breadcrumbs;
  }

  const breadcrumbs = generateBreadcrumbs(currentURL);

  return (
    <div className="breadcrumb tw-flex tw-items-center">
      {breadcrumbs.map((crumb, index) => (
        <Text className="breadcrumb-text tw-items-center" display="flex" mr="xs" key={index}>
          {index < breadcrumbs.length - 1 ? (
            <Link className="breadcrumb-link tw-mr-2" to={crumb.redirectTo} style={{ lineHeight: '12px' }}>
              {crumb.label}
            </Link>
          ) : (
            <Link
              className="breadcrumb-link tw-text-[#344054] tw-font-semibold"
              to={crumb.redirectTo}
              style={{ lineHeight: '12px', marginTop: '6px' }}
            >
              {crumb.label}
            </Link>
          )}
          <span>
            {index < breadcrumbs.length - 1 && (
              <IconChevronRight size="16px" color="#D0D5DD" style={{ marginTop: '2px' }} />
            )}
          </span>
        </Text>
      ))}
    </div>
  );
};

export default BreadcrumbMain;
