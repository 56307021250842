/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Card, Box, Loader, TextInput, Select, MultiSelect } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVICECATEGORY } from '../../utils/constant';
import { useMedplum } from '@medplum/react';
import { createUpdateService, listingPage } from '../../utils/CustomAPI';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import TitleComponent from '../../components/TitleComponent';

interface ServiceFormProps {
  name: string;
  providerGroupIds: any;
  category: string;
  visitTypes: string[];
  duration: string;
  price: number;
  status: string;
}

const Services = () => {
  const medplum = useMedplum();
  const serviceId = useParams().id;
  const navigate = useNavigate();
  const apiUrl = 'get-providergroup-list';
  const count = 20;
  const offset = 0;
  const sort = '_lastUpdated';
  const [isLoading, setIsLoading] = useState(false);
  const [providerGroupList, setProviderGroupList] = useState<any[]>([]);
  const [formData, setFormData] = useState<ServiceFormProps>();

  useEffect(() => {
    if (serviceId) {
      medplum
        .readResource('HealthcareService', serviceId)
        .then((res: any) => {
          const extensionMap = res?.extension.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.url]: curr.valueString }),
            {}
          );
          setFormData({
            name: res?.name,
            providerGroupIds: res?.providedBy?.reference.split('/')?.[1] || '',
            category: res?.category?.[0].coding?.[0].code || '',
            visitTypes: res.type.map((type: any) => type.coding[0].code),
            duration: extensionMap['http://estimated-duration-minutes']
              ? JSON.stringify(JSON.parse(extensionMap['http://estimated-duration-minutes']))
              : '',
            price: extensionMap['http://default-price'] ? Number(JSON.parse(extensionMap['http://default-price'])) : 0,
            status: res?.active ? 'true' : 'false',
          });
        })
        .catch((error: any) => {
          console.error('Error reading resource:', error);
        });
    }
  }, []);

  // handle input change event
  const handleInputChange = useCallback((event: any) => {
    const { name, value } = event.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  // handle select change event
  const handleSelect = useCallback((name: string, value: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  // fetch provider group list
  useEffect(() => {
    listingPage(medplum, apiUrl, count, offset, sort)
      .then((res: any) => {
        setProviderGroupList(
          res?.data?.entry.map((entry: any) => ({
            value: entry.resource?.id,
            label: entry?.resource?.name.toLowerCase(),
          }))
        );
      })
      .catch((error: any) => {
        console.error('Error reading resource:', error);
      });
  }, []);

  const handleSubmit = () => {
    // create health care service resource
    setIsLoading(true);
    createUpdateHealthCareService();
  };

  const createUpdateHealthCareService = () => {
    const payload = {
      name: formData?.name,
      provider_group_ids: serviceId ? [formData?.providerGroupIds] : formData?.providerGroupIds,
      categories: [formData?.category],
      visit_types: formData?.visitTypes,
      estimated_duration: formData?.duration,
      default_price: JSON.stringify(formData?.price),
      active: Boolean(formData?.status),
      id: serviceId,
    };
    createUpdateService(medplum, payload)
      .then(() => {
        showNotification({
          color: 'green',
          message: `${serviceId ? 'Service updated successfully' : 'Service created successfully'}`,
        });
        setIsLoading(false);
        navigate('/HealthcareService');
      })
      .catch((error: any) => {
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(error) });
      });
  };

  return (
    <>
      <Box px="lg" pt="sm" mt={65} pb={20}>
        <TitleComponent title={`${serviceId ? 'Update' : 'Add new'} Service`} />
      </Box>

      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="card-bg">
          <Card shadow="sm" radius="lg">
            <div className="tw-max-w-3xl tw-mt-5">
              <div className="tw-space-y-4">
                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Service Name<span className="tw-text-red-500">*</span>
                    </label>
                    <TextInput
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Service Name"
                      name="name"
                      value={formData?.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Provider Group
                    </label>
                    {serviceId ? (
                      <Select
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        placeholder="Provider Group"
                        name="providerGroupIds"
                        value={formData?.providerGroupIds}
                        data={providerGroupList}
                        searchable
                        required
                        onChange={(e) => handleSelect('providerGroupIds', e)}
                      />
                    ) : (
                      <MultiSelect
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        placeholder="Provider Group"
                        name="providerGroupIds"
                        defaultValue={formData?.providerGroupIds}
                        data={providerGroupList}
                        searchable
                        required
                        onChange={(e) => handleSelect('providerGroupIds', e)}
                      />
                    )}
                  </div>
                </div>
                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Service Category
                    </label>
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Service Category"
                      name="category"
                      value={formData?.category}
                      data={SERVICECATEGORY}
                      required
                      onChange={(e) => handleSelect('category', e)}
                    />
                  </div>

                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Visit Type
                    </label>
                    <MultiSelect
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Visit Types"
                      name="visitTypes"
                      value={formData?.visitTypes}
                      data={[
                        { value: 'audio', label: 'Audio' },
                        { value: 'video', label: 'Video' },
                        { value: 'asynchronous', label: 'Asynchronous' },
                      ]}
                      required
                      onChange={(e) => handleSelect('visitTypes', e)}
                    />
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Estimated Duration<span className="tw-text-red-500">*</span>
                    </label>
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Estimated Duration"
                      name="duration"
                      value={formData?.duration}
                      data={Array.from({ length: 6 }, (_, i) => ({
                        value: `${(i + 1) * 15}`,
                        label: `${(i + 1) * 15} Minutes`,
                      }))}
                      required
                      onChange={(e) => handleSelect('duration', e)}
                      searchable
                    />
                  </div>

                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Default price ($)<span className="tw-text-red-500">*</span>
                    </label>
                    <TextInput
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Default Price ($)"
                      name="price"
                      defaultValue={formData?.price}
                      onChange={handleInputChange}
                      required
                      type="number"
                    />
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Status<span className="tw-text-red-500">*</span>
                    </label>
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      data={[
                        { value: 'true', label: 'Active' },
                        { value: 'false', label: 'Inactive' },
                      ]}
                      placeholder="Status"
                      value={formData?.status}
                      name="status"
                      onChange={(event) => handleSelect('status', event)}
                      required
                      searchable
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Box>

        {/* Buttons */}
        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/HealthcareService`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }} disabled={isLoading}>
              {isLoading ? (
                <>
                  {serviceId ? 'Updating...' : 'Creating...'}Updating...{' '}
                  <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : serviceId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default Services;
