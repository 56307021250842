/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, Card, Checkbox, Grid, Select } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { IconCloudUpload, IconFileTypeCsv } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { uploadPatient } from '../../utils/CustomAPI';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import TitleComponent from '../../components/TitleComponent';
import { useNavigate } from 'react-router-dom';

const UploadPatients = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [providerGroup, setProviderGroup] = useState([]);
  const [provider, setProvider] = useState([]);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [servicesResponse, setServicesResponse] = useState<any>([]);
  const [formData, setFormData] = useState({
    services: '',
    providerGroup: '',
    provider: '',
    createAccount: '',
  });

  useEffect(() => {
    getHealthService();
  }, []);

  const getHealthService = () => {
    medplum
      .search('HealthcareService')
      .then((res: any) => {
        setServicesResponse(res?.entry);
        setServices(
          res?.entry
            .map((entry: any) => ({
              value: entry.resource?.id,
              label: entry?.resource?.name,
            }))
            .reduce((acc: any, current: any) => {
              const x = acc.find((item: any) => item.label === current.label);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, [])
        );
        // setProviderGroup(
        //   res?.entry.map((entry: any) => ({
        //     value: entry.resource?.providedBy.reference?.split('/')[1],
        //     label: entry.resource?.providedBy.display,
        //   }))
        // );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleFileChange = (file: File | null) => {
    setCsvFile(file);
  };

  // Handle select change value and set form data
  const handleOnSelect = (name: any, e: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e,
    }));
  };

  // Handle checkbox change and set form data
  const handleCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: checked ? 'yes' : 'no',
    }));
  };

  const handlePatientUpload = () => {
    uploadPatient(medplum, csvFile, formData)
      .then(() => {
        showNotification({ color: 'green', message: 'Patient csv uploaded' });
      })
      .catch((error: any) => {
        showNotification({ color: 'red', message: normalizeErrorString(error) });
      });
  };

  const handleServiceChange = (event:any , e: any) => {
    //filter provider based on selected service name
    const selectedService: any = servicesResponse.filter((service: any) => service.resource.name === e.label);

    setProviderGroup(
      selectedService.map((entry: any) => ({
        value: entry.resource?.providedBy.reference?.split('/')[1],
        label: entry.resource?.providedBy.display,
      }))
    );
    handleOnSelect('services', event)
  };

  const handleProviderChange = (e: any) => {
    medplum
      .search('PractitionerRole', {
        organization: `Organization/${e}`,
      })
      .then((res: any) => {
        console.log(res);
        setProvider(
          res?.entry.map((entry: any) => ({
            value: entry.resource?.practitioner.reference?.split('/')[1],
            label: entry?.resource?.practitioner.display,
          }))
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
    handleOnSelect('providerGroup', e);
  };

  return (
    <>
      <Box px="lg" pt="sm" mt={65} pb={20}>
        <TitleComponent title={`Upload Patients`} />
      </Box>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handlePatientUpload) {
            handlePatientUpload();
          }
        }}
      >
        <Box px="lg" py="sm" className="card-bg">
          <Card shadow="sm" radius="lg">
            <div className="tw-max-w-3xl tw-mt-5">
              <div className="tw-space-y-4">
                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Services<span className="tw-text-red-500">*</span>
                    </label>

                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      data={services}
                      placeholder="Services"
                      value={formData?.services}
                      name="services"
                      onChange={(event, e) => handleServiceChange(event, e)}
                    />
                  </div>
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Provider Group<span className="tw-text-red-500">*</span>
                    </label>
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      data={providerGroup}
                      placeholder="Provider Group"
                      value={formData?.providerGroup}
                      name="providerGroup"
                      onChange={handleProviderChange}
                    />
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Provider<span className="tw-text-red-500">*</span>
                    </label>
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      data={provider}
                      placeholder="Provider"
                      value={formData?.provider}
                      name="provider"
                      onChange={(event) => handleOnSelect('provider', event)}
                    />
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      CSV File<span className="tw-text-red-500">*</span>
                    </label>
                    <div
                      className="tw-w-full tw-max-w-[400px] tw-relative tw-border-[1px] tw-border-[#EAECF0] tw-border-solid tw-rounded-xl tw-p-6"
                      id="dropzone"
                    >
                      <input
                        type="file"
                        accept=".csv"
                        onChange={(e) => handleFileChange(e.target.files ? e.target.files[0] : null)}
                        className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-opacity-0 tw-z-50 tw-cursor-pointer"
                      />
                      <div className="tw-text-center">
                        {csvFile ? (
                          <div className="tw-flex tw-items-center tw-justify-center">
                            <IconFileTypeCsv className="tw-h-12 tw-w-12 tw-text-[#4DB5BA]" />
                            <span className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900">{csvFile.name}</span>
                          </div>
                        ) : (
                          <>
                            <IconCloudUpload className="tw-mx-auto tw-h-12 tw-w-12" />

                            <h3 className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">
                              <span className="tw-text-[#4DB5BA] tw-font-semibold">Click to upload</span>
                              <span className="tw-text-[#475467] tw-font-normal"> or drag and drop</span>
                            </h3>
                            <p className="tw-mt-1 tw-text-sm tw-text-[#475467] tw-font-normal">
                              CSV file (max. 50 kb in size)
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-mb-4">
                  <div className="tw-w-1/3 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Create Account
                    </label>
                    <Checkbox
                      label="Yes"
                      name="createAccount"
                      size="sm"
                      checked={formData?.createAccount === 'yes'}
                      onChange={handleCheckBoxChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Box>

        {/* Buttons */}

        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/Patient`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }}>
              Upload Patients
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default UploadPatients;
