export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
  readonly color?: 'navy' | 'white';
}

export function Logo(props: LogoProps): JSX.Element {
  const color = props.color || 'navy';
  return (
    <div>
      {color === 'navy' && (
        <img src="../../img/logo-vertical-navy.png" style={{width: props.size}} alt='healthside Logo' />
      )}
      {color === 'white' && (
        <img src="../../img/logo-vertical-white.png" style={{width: props.size}} alt='healthside Logo' />
      )}
    </div>
  );
}
