/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, Card, Grid, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMedplum } from '@medplum/react';
import { checkInputValue } from '../../utils/util';
import { createPatient } from './PatientResource';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import PatientDetails from './PatientDetails';
import { checkUserExists, createUpdatePatient, createUpdatePatientAccount, createUpdatePatientResource } from '../../utils/CustomAPI';
import { format } from 'date-fns';
import { Coverage, Patient } from '@medplum/fhirtypes';
import TitleComponent from '../../components/TitleComponent';

const AddPatients = () => {
  const patientId = useParams().id;
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { insuranceNumber, coverageId, userId } = location?.state || {};
  const initialFormData = {
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    mobile: '',
    email: '',
    street: '',
    city: '',
    state: '',
    suiteApt: '',
    postalCode: '',
    insurance: '',
    insuranceNumber: '',
    createAccount: false,
    userId: '',
    providerGroup: '',
    provider: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (patientId) {
      medplum
        .readResource('Patient', patientId)
        .then((res: any) => {
          const telecomMap = res?.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});

          setFormData((prevData: any) => ({
            ...prevData,
            firstName: res.name[0].given[0],
            lastName: res.name[0].family,
            dob: res.birthDate,
            gender: res.gender,
            street: res.address[0].line[0],
            city: res.address[0].city,
            state: res.address[0].state,
            postalCode: res.address[0].postalCode,
            mobile: telecomMap['phone'] || '',
            email: telecomMap['email'] || '',
            insuranceNumber: insuranceNumber || '',
            userId: userId,
            providerGroup: res?.managingOrganization.display,
            provider: res?.generalPractitioner[0]?.display,
          }));
        })
        .catch((error: any) => {
          console.error('Error reading resource:', error);
          showNotification({ color: 'red', message: 'Failed' });
        });
    }
  }, []);

  // Handle input change value and set form data
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  };

  // handle date change
  const handleDateChange = (date: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      dob: format(date, 'YYYY-MM-DD'),
    }));
  };

  // Handle checkbox change and set form data
  const handleCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // Handle select change value and set form data
  const handleOnSelect = (name: any, e: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e,
    }));
  };

  const handleSubmit = async() => {
    //create patien resource
console.log(formData);
    try {
      const response = await checkUserExists(medplum, { email: formData.email });
      if (response?.exists) {
        showNotification({ color: 'red', message: 'Email already exists' });
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showNotification({ color: 'red', message: 'Error checking user existence' });
      return;
    }

    const patient = createPatient(formData, patientId);
    setIsLoading(true);
    if (!formData?.providerGroup) {
      showNotification({ color: 'red', message: 'Please select Provider Group' });
      return;
    } else if (!formData?.provider) {
      showNotification({ color: 'red', message: 'Please select Provider' });
      return;
    }
    createUpdatePatient(medplum, patient)
      .then((res) => {
        createUpdatePatientDataResource(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  const createUpdatePatientDataResource = async (res: Patient) => {
    //create coverage resource
    const coverage: Coverage = {
      resourceType: 'Coverage',
      status: 'active',
      beneficiary: {
        reference: `Patient/${res.id}`,
        display: `${formData.firstName} ${formData.lastName}`,
      },
      payor: [
        {
          reference: `Patient/${res.id}`,
          display: `${formData.firstName} ${formData.lastName}`,
        },
      ],
      subscriberId: formData.insuranceNumber,
    };

    //add coverage id if it is update
    if (coverageId) {
      coverage.id = coverageId;
    }
    createUpdatePatientResource(medplum, coverage)
      .then(() => {
        //if create account is checked then create patient account
        if (formData.createAccount) {
          //create patient account
           createAccount(res.id);
        } else {
          setIsLoading(false);
          showNotification({
            color: 'green',
            message: `${patientId ? 'Patient updated successfully' : 'Patient created successfully'}`,
          });
          navigate('/Patient');
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  //create patient account
  const createAccount = async (profileId: any) => {
    const payload = {
      id: userId,
      profile_id: profileId,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
    };

    createUpdatePatientAccount(medplum, payload)
      .then(() => {
        setIsLoading(false);
        navigate('/Patient');
        showNotification({
          color: 'green',
          message: `${patientId ? 'Patient updated successfully' : 'Patient created successfully'}`,
        });
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  return (
    <>
      <Box px="lg" pt="sm" mt={65} pb={20}>
        <TitleComponent title={`${patientId ? 'Update' : 'Add new'} patient`} />
      </Box>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="card-bg">
          <Card shadow="sm" radius="lg">
            <PatientDetails
              formData={formData}
              handleInputChange={handleInputChange}
              handleOnSelect={handleOnSelect}
              handleCheckBoxChange={handleCheckBoxChange}
              handleDateChange={handleDateChange}
            />
          </Card>
        </Box>

        {/* Buttons */}
        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/Patient`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }} disabled={isLoading}>
              {isLoading ? (
                <>
                  {patientId ? 'Updating...' : 'Creating...'} <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : patientId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default AddPatients;
