/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { TextInput, MultiSelect, PasswordInput, Select } from '@mantine/core';
import { checkInputValue } from '../../utils/util';
import { USA_STATES } from '../../utils/constant';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import { useLocation, useParams } from 'react-router-dom';
import { IconCloudUpload, IconPointFilled } from '@tabler/icons-react';

interface ProviderDetailsProps {
  formData: any;
  error: boolean;
}

interface ProviderGroupList {
  label: string;
  value: string;
}

const ProviderDetails = forwardRef<{ getData: () => any }, ProviderDetailsProps>(({ formData, error }, ref) => {
  const medplum = useMedplum();
  const providerId = useParams().id;
  const location = useLocation();
  const { role } = location?.state || {};
  const [providerGroupList, setProviderGroupList] = React.useState<ProviderGroupList[]>([{ value: '', label: '' }]);

  const [providerData, setProviderData] = useState<any>();
  const [passowrdError, setPassowrdError] = useState(error || false);
  const [isLoading, setIsLoading] = useState(providerId ? false : true);
  const [services, setServices] = useState<any>([]);
  const [servicesResponse, setServicesResponse] = useState<any>([]);

  const handlePasswordChange = useCallback(
    (event: any) => {
      const { name, value } = event.target;

      if (name === 'confirm_password') {
        if (providerData?.password !== value) {
          setPassowrdError(true);
        } else {
          setPassowrdError(false);
        }
      }
      setProviderData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [providerData]
  );

  useEffect(() => {
    setTimeout(() => {
      setProviderData(formData);
      if (formData === undefined) {
        setProviderData((prevData: any) => ({
          ...prevData,
          status: 'true',
        }));
      }
      setIsLoading(true);
    }, 2000);
  }, [formData]);

  useEffect(() => {
    setProviderData((prevData: any) => ({
      ...prevData,
      status: 'true',
    }));
  }, []);

  // Handle input change value and set form data
  const handleInputChange = useCallback((event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setProviderData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  }, []);

  // Handle multi select change value and set form data
  const handleMultiSelectChange = useCallback((value: any, fieldName: string) => {
    setProviderData((prevData: any) => ({
      ...prevData,
      [fieldName]: value,
    }));
  }, []);

  // Handle select change value and set form data
  const handleSelect = useCallback((name: any, e: any) => {
    setProviderData((prevData: any) => ({
      ...prevData,
      [name]: e,
    }));
  }, []);

  // Handle file change and set form data
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    setProviderData((prevData: any) => ({
      ...prevData,
      image: file,
    }));
    setProviderData((prevData: any) => ({
      ...prevData,
      imageTitle: file.name,
    }));
    //create binary for image
    medplum
      .createBinary(file, file.name, file.type, () => {})
      .then((binary: Binary) => {
        console.log('binary', binary);
        setProviderData((prevData: any) => ({
          ...prevData,
          imageUrl: binary.url,
        }));
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };

  //const brandIds = brands?.map((brand: { id: string; name: string }) => brand.id);
  useEffect(() => {
      getHealthService();
  }, []);

  const getHealthService = () => {
    medplum
      .search('HealthcareService')
      .then((res: any) => {
        setServicesResponse(res?.entry);
        setServices(
          res?.entry
            .map((entry: any) => ({
              value: entry.resource?.id,
              label: entry?.resource?.name,
            }))
            .reduce((acc: any, current: any) => {
              const x = acc.find((item: any) => item.value === current.value);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, [])
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleServiceChange = (e: any) => {
    //filter provider based on selected service name
    const selectedService: any = servicesResponse.filter((service: any) => service.resource.id === e);

    setProviderGroupList(
      selectedService.map((entry: any) => ({
        value: entry.resource?.providedBy.reference?.split('/')[1],
        label: entry.resource?.providedBy.display,
      }))
    );
    handleMultiSelectChange(e, 'servicesId');
  };

  useImperativeHandle(ref, () => ({
    getData: () => providerData,
  }));

  return (
    <>
      {isLoading && (
        <div className="tw-max-w-3xl tw-mt-5">
          <h2 className="tw-text-2xl tw-text-[#101828] tw-font-semibold tw-mb-6">Provider Details</h2>
          <div className="tw-space-y-4">
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  First name<span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="First Name"
                  name="firstName"
                  value={providerData?.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Last name<span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="Last Name"
                  name="lastName"
                  value={providerData?.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Mobile<span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  type="number"
                  value={providerData?.providerMobile}
                  name="providerMobile"
                  placeholder="Mobile Number"
                  onChange={handleInputChange}
                  maxLength={10}
                  required
                />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Email<span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  type="email"
                  placeholder="Email"
                  name="providerEmail"
                  value={providerData?.providerEmail}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="tw-mt-[1rem]">
              <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">Address</label>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Street<span className="tw-text-red-500">*</span>
                  </label>
                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="Street Address"
                    name="street"
                    value={providerData?.street}
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    City<span className="tw-text-red-500">*</span>
                  </label>
                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="City"
                    name="city"
                    value={providerData?.city}
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    State<span className="tw-text-red-500">*</span>
                  </label>
                  <Select
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    data={USA_STATES}
                    placeholder="State"
                    value={providerData?.state}
                    name="state"
                    searchable
                    onChange={(event) => handleSelect('state', event)}
                    required
                  />
                </div>

                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Suite/Apt<span className="tw-text-red-500">*</span>
                  </label>
                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="Suite/Apt"
                    name="suiteApt"
                    value={providerData?.suiteApt}
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Postal Code<span className="tw-text-red-500">*</span>
                  </label>
                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="Zip Code"
                    name="postalCode"
                    value={providerData?.postalCode}
                    required
                    onChange={handleInputChange}
                    maxLength={6}
                  />
                </div>
              </div>
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  License Status<span className="tw-text-red-500">*</span>
                </label>

                <div className="tw-relative tw-flex tw-items-center tw-justify-between">
                  <Select
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px', paddingLeft: '30px' } }}
                    data={[
                      { value: 'true', label: 'Active' },
                      { value: 'false', label: 'Inactive' },
                    ]}
                    value={providerData?.status}
                    name="status"
                    required
                    onChange={(event) => handleSelect('status', event)}
                    className="tw-w-full"
                  />
                  <div className="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
                    <IconPointFilled
                      className="tw-w-[18px]"
                      color={providerData?.status === 'true' ? '#17B26A' : '#F04438'}
                    />
                  </div>
                </div>
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Display Name<span className="tw-text-red-500">*</span>
                </label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="Display Name"
                  name="displayName"
                  value={providerData?.displayName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div>
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Image</label>
              <div
                className="tw-w-full tw-max-w-[400px] tw-relative tw-border-[1px] tw-border-[#EAECF0] tw-border-solid tw-rounded-xl tw-p-6"
                id="dropzone"
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-opacity-0 tw-z-50 tw-cursor-pointer"
                />
                <div className="tw-text-center">
                  {providerData?.imageUrl ? (
                    <img
                      src={providerData.imageUrl}
                      alt={providerData.imageTitle}
                      className="tw-mx-auto tw-max-h-40 tw-object-cover tw-rounded-lg"
                    />
                  ) : (
                    <>
                      <IconCloudUpload className="tw-mx-auto tw-h-12 tw-w-12" />

                      <h3 className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">
                        <span className="tw-text-[#4DB5BA] tw-font-semibold">Click to upload</span>
                        <span className="tw-text-[#475467] tw-font-normal"> or drag and drop</span>
                      </h3>
                      <p className="tw-mt-1 tw-text-sm  tw-text-[#475467] tw-font-normal">
                        SVG, PNG, JPG (size. 800x800px)
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Services<span className="tw-text-red-500">*</span>
                </label>

                <Select
                  placeholder="Services"
                  name="servicesId"
                  value={formData?.servicesId}
                  data={services}
                  required
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  onChange={handleServiceChange}
                  searchable
                />
              </div>

              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Provider Groups<span className="tw-text-red-500">*</span>
                </label>

                <MultiSelect
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  placeholder="Provider Groups"
                  name="providerGroupIds"
                  defaultValue={role?.length > 0 ? role.map((item: any) => item.organizationId) : []}
                  data={providerGroupList}
                  required
                  searchable
                  onChange={(value) => handleMultiSelectChange(value, 'providerGroupIds')}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Password<span className="tw-text-red-500">*</span>
                </label>
                <PasswordInput
                  value={providerData?.password}
                  name="password"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  required
                  autoComplete="new-password"
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                />
              </div>

              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                  Confirm Password<span className="tw-text-red-500">*</span>
                </label>
                <PasswordInput
                  value={providerData?.confirm_password}
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={handlePasswordChange}
                  required
                  error={passowrdError ? 'Password does not match' : null}
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ProviderDetails;
