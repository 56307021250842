/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Practitioner } from "@medplum/fhirtypes";


export const createPractitioner = (formData: any, providerId: string | undefined, credentialsData: any) => {
    const practitioner: Practitioner = {
        resourceType: 'Practitioner',
        name: [
            {
                family: formData.lastName,
                given: [formData.firstName],
            },
        ],
        telecom: [
            {
                system: 'phone',
                value: formData.providerMobile,
            },
            {
                system: 'email',
                value: formData.providerEmail,
            },
        ],
        active: formData.status === 'true',
        address: [
            {
                line: [formData.street, formData.suiteApt],
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
            },
        ],
        photo: [
            {
                contentType: 'image/png',
                title: formData?.imageTitle,
                url: formData?.imageUrl,
            },
        ],
        identifier: [
            {
                system: 'http://practitoner-npi',
                value: credentialsData?.npi,
            },
            {
                system: "practitioner-type",
                value: "Practitioner"
            },
            {
                system: "org-type",
                value: "ProviderGroup"
            }
        ],
        qualification: [
            {
                code: {
                    coding: [
                        {
                            code: credentialsData?.title,
                            display: credentialsData?.title,
                        },
                    ],
                },
            },
        ],
        extension: [
            {
                url: 'http://state-licenses',
                valueString: JSON.stringify(credentialsData?.LicenseFields),
            },
            {
                url: 'http://DEA-licenses',
                valueString: JSON.stringify(credentialsData?.DEALicenseFields),
            }
        ],
    };

    if (formData.displayName) {
        practitioner.extension?.push({
            url: 'http://display-name',
            valueString: formData.displayName,
        });
    }

    if(providerId) {
        practitioner.id = providerId;
    }
    return practitioner;
}