import { Space } from '@mantine/core';
import { MEDPLUM_VERSION } from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import { AppShell, Loading, Logo, NavbarMenu, useMedplum } from '@medplum/react';
import {
  Icon,
  IconBrandAsana,
  IconBuilding,
  IconForms,
  IconLockAccess,
  IconMicroscope,
  IconPackages,
  IconReceipt,
  IconReportMedical,
  IconWebhook,
  IconStethoscope,
  IconUsersGroup,
  IconFirstAidKit,
  IconUser,
  IconCalendar
} from '@tabler/icons-react';
import { Suspense } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import '@mantine/dates/styles.css';
import './index.css';
import './App.css';

export function App(): JSX.Element {
  const medplum = useMedplum();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <AppShell
      logo={<Logo size={150} />}
      pathname={location.pathname}
      searchParams={searchParams}
      version={MEDPLUM_VERSION}
      menus={userConfigToMenu(config)}
      displayAddBookmark={!!config?.id}
    >
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: UserConfiguration | undefined): NavbarMenu[] {
  const result =
    config?.menu?.map((menu) => ({
      title: menu.title,
      links:
        menu.link?.map((link) => ({
          label: getDisplayName(link.name as string).displayName,
          href: link.target as string,
          icon: getIcon(link.target as string),
        })) || [],
    })) || [];

  // result.push({
  //   title: 'Settings',
  //   links: [
  //     {
  //       label: 'Security',
  //       href: '/security',
  //       icon: <IconLock />,
  //     },
  //   ],
  // });

  return result;
}

const resourceTypeToIcon: Record<string, Icon> = {
  Patient: IconUser,
  Practitioner: IconStethoscope,
  Organization: IconBuilding,
  Brands: IconBuilding,
  ProviderGroup: IconStethoscope,
  Staff: IconUsersGroup,
  ServiceRequest: IconReceipt,
  DiagnosticReport: IconReportMedical,
  Questionnaire: IconForms,
  admin: IconBrandAsana,
  AccessPolicy: IconLockAccess,
  Subscription: IconWebhook,
  batch: IconPackages,
  Observation: IconMicroscope,
  HealthcareService: IconFirstAidKit,
  Appointment: IconCalendar,
};

function getIcon(to: string): JSX.Element | undefined {
  try {
    const resourceType = new URL(to, 'https://app.medplum.com').pathname.split('/')[1];
    if (resourceType in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[resourceType];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Brands: 'Brands',
  Provider: 'Providers',
  ProviderGroup: 'Provider Groups',
  Staff: 'Staff',
  HealthcareService: 'Services',
  Appointments: 'Appointments',
};

function getDisplayName(name: string): Record<string, string> {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}
