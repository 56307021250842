/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Card, Box, Loader, TextInput, Select, PasswordInput } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { checkUserExists, createUpdateStaff, listingPage } from '../../utils/CustomAPI';
import { checkInputValue } from '../../utils/util';
import { ROLE } from '../../utils/constant';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import { createStaffResource } from './StaffResource';
import { IconPointFilled } from '@tabler/icons-react';
import TitleComponent from '../../components/TitleComponent';

const Staff = () => {
  const medplum = useMedplum();
  const staffId = useParams().id;
  const location = useLocation();
  const { userId, role } = location?.state || {};
  const navigate = useNavigate();
  const apiUrl = 'get-providergroup-list';
  const count = 20;
  const offset = 0;
  const sort = '_lastUpdated';
  const [isLoading, setIsLoading] = useState(false);
  const [providerGroupList, setProviderGroupList] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>();
  const [passowrdError, setPasswordError] = useState(false);

  useEffect(() => {
    if (staffId) {
      medplum.readResource('Practitioner', staffId).then((res: any) => {
        const telecomMap = res.telecom.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
        setFormData((prevData: any) => ({
          ...prevData,
          firstName: res?.name[0].given[0],
          lastName: res.name[0].family,
          phone: telecomMap['phone'] || '',
          providerEmail: telecomMap['email'] || '',
          status: res.active ? 'true' : 'false',
          role: role?.role,
          organizationId: role.organizationId,
        }));
      });
    }
  }, []);

  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      status: 'true',
    }));
  }, []);

  // Handle input change value and set form data
  const handleInputChange = useCallback((event: any) => {
    const { name, value } = event.target;
    const checkedValue = checkInputValue(name, value);

    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  }, []);

  const handlePasswordChange = useCallback(
    (event: any) => {
      const { name, value } = event.target;

      if (name === 'confirm_password') {
        if (formData?.password !== value) {
          setPasswordError(true);
        } else {
          setPasswordError(false);
        }
      }

      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [formData]
  );

  const handleSelect = useCallback((name: string, value: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  useEffect(() => {
    listingPage(medplum, apiUrl, count, offset, sort)
      .then((res: any) => {
        setProviderGroupList(
          res?.data?.entry.map((entry: any) => ({
            value: entry.resource?.id,
            label: entry?.resource?.name.toLowerCase(),
          }))
        );
      })
      .catch((error: any) => {
        console.error('Error reading resource:', error);
      });
  }, [medplum]);

  const handleSubmit = async() => {
    try {
      const response = await checkUserExists(medplum, { email: formData.providerEmail });
      if (response?.exists) {
        showNotification({ color: 'red', message: 'Email already exists' });
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showNotification({ color: 'red', message: 'Error checking user existence' });
      return;
    }


    const practitioner = createStaffResource(formData, staffId);
    if (formData.password !== formData.confirm_password) {
      setPasswordError(true);
      return;
    } 
    if (!formData.providerGroupIds) {
      showNotification({ color: 'red', message: 'Please select provider group' });
      return;
    } else if (!formData.role) {
      showNotification({ color: 'red', message: 'Please select role' });
      return;
    }
    setIsLoading(true);
    if (staffId) {
      //update staff resource
      medplum
        .updateResource(practitioner)
        .then(() => {
          createStaff();
        })
        .catch((err) => {
          setIsLoading(false);
          showNotification({ color: 'red', message: normalizeErrorString(err) });
        });
    } else {
      //create staff resource
      createStaff();
    }
  };

  const createStaff = async() => {
    const payload = {
      provider_group_id: formData.providerGroupIds,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phone,
      email: formData.providerEmail,
      password: formData.password,
      user_role: formData.role,
      id: userId,
      profile_id: staffId,
    };


    createUpdateStaff(medplum, payload)
      .then(() => {
        setIsLoading(false);
        showNotification({
          color: 'green',
          message: `${staffId ? 'Satff account updated successfully' : 'Staff account created successfully'}`,
        });
        navigate(`/Staff`);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  };

  return (
    <div>
      <Box px="lg" pt="sm" mt={65} pb={20}>
        <TitleComponent title={`${staffId ? 'Update' : 'Add new'} Staff`} />
      </Box>

      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (handleSubmit) {
            handleSubmit();
          }
        }}
      >
        <Box px="lg" py="sm" className="card-bg">
          <Card shadow="sm" radius="lg">
            <div className="tw-max-w-3xl tw-mt-5">
              <h2 className="tw-text-2xl tw-text-[#101828] tw-font-semibold tw-mb-6">Staff Details</h2>
              <div className="tw-space-y-4">
                <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      First name<span className="tw-text-red-500">*</span>
                    </label>
                    <TextInput
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="First Name"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                      Last name<span className="tw-text-red-500">*</span>
                    </label>
                    <TextInput
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                      placeholder="Last Name"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Mobile<span className="tw-text-red-500">*</span>
                  </label>
                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    type="number"
                    value={formData?.phone}
                    name="phone"
                    placeholder="Mobile Number"
                    onChange={handleInputChange}
                    maxLength={10}
                    required

                  />
                </div>

                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Email<span className="tw-text-red-500">*</span>
                  </label>

                  <TextInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    type="email"
                    placeholder="Email"
                    name="providerEmail"
                    value={formData?.providerEmail}
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Password<span className="tw-text-red-500">*</span>
                  </label>
                  <PasswordInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    value={formData?.password}
                    name="password"
                    placeholder="Password"
                    onChange={handlePasswordChange}
                    required
                    autoComplete="new-password"
                  />
                </div>

                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Confirm Password<span className="tw-text-red-500">*</span>
                  </label>
                  <PasswordInput
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    value={formData?.confirm_password}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    onChange={handlePasswordChange}
                    required
                    error={passowrdError ? 'Password does not match' : null}
                  />
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Provider Group<span className="tw-text-red-500">*</span>
                  </label>
                  <Select
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="Provider Group"
                    name="providerGroupIds"
                    value={formData?.organizationId}
                    data={providerGroupList}
                    required={true}
                    onChange={(e) => handleSelect('providerGroupIds', e)}
                  />
                </div>

                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Role<span className="tw-text-red-500">*</span>
                  </label>
                  <Select
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    placeholder="Role"
                    name="role"
                    value={formData?.role}
                    data={ROLE}
                    required
                    onChange={(e) => handleSelect('role', e)}
                  />
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-py-2">
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Status<span className="tw-text-red-500">*</span>
                  </label>
                  <div className="tw-relative tw-flex tw-items-center tw-justify-between">
                    <Select
                      styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px', paddingLeft: '30px' } }}
                      data={[
                        { value: 'true', label: 'Active' },
                        { value: 'false', label: 'Inactive' },
                      ]}
                      placeholder="License Status"
                      value={formData?.status}
                      name="status"
                      onChange={(event) => handleSelect('status', event)}
                      className="tw-w-full"
                      required

                    />
                    <div className="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
                      <IconPointFilled
                        className="tw-w-[18px]"
                        color={formData?.status === 'true' ? '#17B26A' : '#F04438'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Box>

        {/* Buttons */}
        <Grid px="lg" className="org-form card-bg ">
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '12px' }}>
            <Button
              variant="outline"
              type="button"
              onClick={() => navigate(`/Staff`)}
              style={{ marginRight: '8px' }}
              className="btn-bdr tw-rounded-lg"
            >
              Cancel
            </Button>
            <Button type="submit" className="act-btn tw-rounded-lg" style={{ marginTop: '0' }} disabled={isLoading}>
              {isLoading ? (
                <>
                  {staffId ? 'Updating...' : 'Creating...'}
                  <Loader size={20} ml={10} color="rgba(99, 99, 99, 1)" />
                </>
              ) : staffId ? (
                'Update'
              ) : (
                'Save'
              )}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </div>
  );
};

export default Staff;
