/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import { MultiSelect} from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { listingPage } from '../../utils/CustomAPI';
import { USA_STATES } from '../../utils/constant';
import { IconPointFilled } from '@tabler/icons-react';

interface ProviderGroupDetailsProps {
  formData: any;
  handleInputChange: (event: any) => void;
  handleMultiSelectChange: (event: any) => void;
  brands: any;
  handleSelect: (name: any, event: any) => void;
}
interface BrandList {
  label: string;
  value: string;
}
const ProviderGroupDetails: React.FC<ProviderGroupDetailsProps> = ({
  formData,
  handleInputChange,
  handleMultiSelectChange,
  brands,
  handleSelect,
}) => {
  const medplum = useMedplum();
  const apiUrl = 'get-brand-list';
  const count = 20;
  const offset = 0;
  const sort = '_lastUpdated';
  const [brandList, setBrandList] = React.useState<BrandList[]>([{ value: '', label: '' }]);
  //const [serviceList, setServiceList] = React.useState<BrandList[]>([]);

  const brandIds = brands?.map((brand: { id: string; name: string }) => brand.id);

  useEffect(() => {
    listingPage(medplum, apiUrl, count, offset, sort)
      .then((res: any) => {
        setBrandList(
          res.data.entry.map((entry: any) => ({
            value: entry.resource?.id,
            label: entry?.resource?.name.toLowerCase(),
          }))
        );
      })
      .catch((error: any) => {
        console.error('Error reading resource:', error);
      });
    //getService();
  }, []);

  // const getService = () => {
  //   medplum
  //     .search('HealthcareService')
  //     .then((res: any) => {
  //       console.log('res', res);
  //       setServiceList(
  //         res.entry.map((entry: any) => ({
  //           value: entry.resource?.id,
  //           label: entry?.resource?.name.toLowerCase(),
  //         }))
  //       );
  //     })
  //     .catch((error: any) => {
  //       console.error('Error reading resource:', error);
  //     });
  // };

  return (
    <div className="tw-max-w-3xl tw-mt-5">
      <p className="tw-text-2xl tw-text-[#101828] tw-font-semibold tw-mb-6">Provider Group</p>

      <div className="tw-space-y-4">
        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          
          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
              Name<span className="tw-text-red-500">*</span>
            </label>
            <input
              type="text"
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
              Mobile<span className="tw-text-red-500">*</span>
            </label>
            <input
              type="number"
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              placeholder="Mobile Number"
              name="providerMobile"
              value={formData.providerMobile}
              onChange={handleInputChange}
              maxLength={10}
              required

            />
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
              Email<span className="tw-text-red-500">*</span>
            </label>
            <input
              type="email"
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              placeholder="Email"
              name="providerEmail"
              value={formData.providerEmail}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div style={{ marginTop: '1 rem' }}>
          <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">Address</label>
          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Street<span className="tw-text-red-500">*</span>
              </label>

              <input
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                placeholder="Street"
                name="street"
                value={formData.street}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                City<span className="tw-text-red-500">*</span>
              </label>
              <input
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="tw-flex tw-flex-wrap tw-mb-4 ">
            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                State<span className="tw-text-red-500">*</span>
              </label>
              <select
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                name="state"
                value={formData.state}
                onChange={(e) => handleSelect('state', e.target.value)}
                required
              >
                <option value="">State</option>
                {USA_STATES.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
              <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                Postal Code<span className="tw-text-red-500">*</span>
              </label>
              <input
                type="number"
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                placeholder="Postal Code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                required
                maxLength={6}
              />
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
              Status<span className="tw-text-red-500">*</span>
            </label>

            <div className="tw-relative tw-flex tw-items-center tw-justify-between">
              <select
                className="tw-w-full tw-pl-6 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                name="status"
                value={formData.status}
                onChange={(e) => handleSelect('status', e.target.value)}
                required

              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <div className="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
              <IconPointFilled className='tw-w-[18px] ' color={formData.status === 'true' ? '#17B26A' : '#F04438'} />              
              </div>
            </div>
          </div>


          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">NPI</label>
            <input
              type="number"
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              placeholder="NPI Number"
              name="npi"
              value={formData.npi}
              onChange={handleInputChange}
              maxLength={10}
            />
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-mb-4 ">
          {/* <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Service</label>
            <select
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
              name="service"
              value={formData.service}
              onChange={(e) => handleSelect('service', e.target.value)}
            >
              <option value="">Service List</option>
              {serviceList.map((service) => (
                <option key={service.value} value={service.value}>
                  {service.label}
                </option>
              ))}
            </select>
          </div> */}

          <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Brands</label>

            <MultiSelect
              styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
              placeholder="Brands"
              name="brands"
              defaultValue={brandIds}
              data={brandList}
              searchable
              onChange={handleMultiSelectChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderGroupDetails;
