/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { Box, Center, Loader, Menu, UnstyledButton } from '@mantine/core';
import { MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_SEARCH_COUNT, Filter, SearchRequest, formatSearchQuery } from '@medplum/core';
import { Bundle, OperationOutcome, Resource, ResourceType, SearchParameter } from '@medplum/fhirtypes';
import {
  IconAdjustmentsHorizontal,
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconPencil,
  IconPlus,
  IconPointFilled,
  IconSearch,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';
import { useMedplum } from '@medplum/react-hooks';
import { SearchFieldEditor } from '../../../react/src/SearchFieldEditor/SearchFieldEditor';
import { SearchFilterEditor } from '../../../react/src/SearchFilterEditor/SearchFilterEditor';
import { SearchFilterValueDialog } from '../../../react/src/SearchFilterValueDialog/SearchFilterValueDialog';
import { getFieldDefinitions } from '../../../react/src/SearchControl/SearchControlField';
import {
  addFilter,
  buildFieldNameString,
  renderValue,
  setPage,
  //getOpString,
} from '../../../react/src/SearchControl/SearchUtils';
import classes from './SearchControl.module.css';
//import { SearchFilterValueDisplay } from '../../../react/src/SearchFilterValueDisplay/SearchFilterValueDisplay';
import { listingPage } from '../utils/CustomAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchPopupMenu } from '../../../react/src/SearchPopupMenu/SearchPopupMenu';
import TitleComponent from '../components/TitleComponent';
import Appointments from './Appointments/Appointments';

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: MouseEvent;

  constructor(resource: Resource, browserEvent: MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}

export interface SearchControlProps {
  readonly search: SearchRequest;
  readonly checkboxesEnabled?: boolean;
  readonly hideToolbar?: boolean;
  readonly hideFilters?: boolean;
  readonly onLoad?: (e: SearchLoadEvent) => void;
  readonly onChange?: (e: SearchChangeEvent) => void;
  readonly onClick?: (e: SearchClickEvent) => void;
  readonly onAuxClick?: (e: SearchClickEvent) => void;
  readonly onNew?: () => void;
  readonly onExportTransactionBundle?: () => void;
}

interface SearchControlState {
  readonly searchResponse?: Bundle;
  readonly selected: { [id: string]: boolean };
  readonly fieldEditorVisible: boolean;
  readonly filterEditorVisible: boolean;
  readonly filterDialogVisible: boolean;
  readonly exportDialogVisible: boolean;
  readonly filterDialogFilter?: Filter;
  readonly filterDialogSearchParam?: SearchParameter;
}

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 * @param props - The SearchControl React props.
 * @returns The SearchControl React node.
 */
export function SearchControl(props: SearchControlProps): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { search, onLoad } = props;
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  // const [apiResponse, setAPIResponse] = useState<any>([]);
  const [resourceName, setResourceName] = useState<string>('');
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [isAppointmentId, setIsAppointmentId] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState<SearchControlState>({
    selected: {},
    fieldEditorVisible: false,
    filterEditorVisible: false,
    exportDialogVisible: false,
    filterDialogVisible: false,
  });

  useEffect(() => {
    const getResourceName = (resourceType: string): string => {
      switch (resourceType) {
        case 'Organization':
          return 'Brand';
        case 'ProviderGroup':
          return 'Provider Group';
        case 'Practitioner':
          return 'Provider';
        case 'Staff':
          return 'Staff';
        case 'HealthcareService':
          return 'Healthcare Service';
        case 'Patient':
          return 'Patient';
        default:
          return location?.pathname?.split('/')[1];
      }
    };
    setResourceName(getResourceName(location.pathname.split('/')[1]));
  }, [location.pathname]);

  const stateRef = useRef<SearchControlState>(state);
  stateRef.current = state;

  const total = search.total ?? 'accurate';

  const loadResults = useCallback(
    (options?: RequestInit) => {
      setOutcome(undefined);
      setIsLoading(true);

      medplum
        .search(
          search.resourceType as ResourceType,
          formatSearchQuery({ ...search, total, fields: undefined }),
          options
        )
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response));
          }
          setIsLoading(false);
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
          setIsLoading(false);
        });
    },
    [medplum, search, total, onLoad]
  );

  //calling custom API to get the patient session details
  const loadBrandListResults = useCallback(() => {
    console.log('Api Call Started');
    setIsLoading(true);
    const apiUrls = {
      '/ProviderGroup': 'get-providergroup-list',
      '/Organization': 'get-brand-list',
      '/Practitioner': 'get-practitioner-list',
      '/Staff': 'get-staff-list',
      '/HealthcareService': 'get-healthcareservice-list',
      '/Patient': 'get-patient-list',
    } as Record<string, string>;

    const apiUrl = apiUrls[location.pathname] || '';

    setOutcome(undefined);
    listingPage(medplum, apiUrl, search.count, search.offset, search)
      .then((response) => {
        setState({ ...stateRef.current, searchResponse: response?.data });
        if (onLoad) {
          onLoad(new SearchLoadEvent(response?.data));
        }
        setIsLoading(false);
      })
      .catch((reason) => {
        setState({ ...stateRef.current, searchResponse: undefined });
        setOutcome(reason);
        setIsLoading(false);
      });
  }, [medplum, search, onLoad]);

  useEffect(() => {
    const resourceTypes = ['Organization', 'ProviderGroup', 'Practitioner', 'Staff', 'HealthcareService', 'Patient'];
    resourceTypes.includes(props.search.resourceType) ? loadBrandListResults() : loadResults();
  }, [loadResults]);

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch - The new search definition.
   */
  function emitSearchChange(newSearch: SearchRequest): void {
    if (location.pathname === '/Staff') {
      props.search.resourceType = 'Staff';
    }

    if (props.onChange) {
      props.onChange(new SearchChangeEvent(newSearch));
    }
  }

  useEffect(() => {
    setSchemaLoaded(false);
    if (props.search.resourceType === 'ProviderGroup') {
      props.search.resourceType = 'Organization';
    } else if (props.search.resourceType === 'Staff') {
      props.search.resourceType = 'Practitioner';
    }

    medplum
      .requestSchema(props.search.resourceType as ResourceType)
      .then(() => {
        setSchemaLoaded(true);
      })
      .catch(console.error);
  }, [medplum, props.search]);

  if (!schemaLoaded) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  const fields = getFieldDefinitions(search);
  let resourceType = search.resourceType;
  const lastResult = state.searchResponse;
  const entries = lastResult?.entry;
  const resources = entries?.map((e) => e.resource);

  fields?.map((field: any) => {
    if (field.name === 'title') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'title',
          name: 'title',
          base: ['Resource'],
          type: 'string',
          expression: 'Resource.qualification[0].code.coding[0].code',
        },
      ];
    } else if (field.name === 'email') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'email',
          name: 'email',
          base: ['Resource'],
          type: 'string',
          expression: "Resource.telecom.where(system='email').value",
        },
      ];
    }
  });

  const filterResources = (resources: any[]): any[] => {
    if (!searchQuery) {
      return resources;
    }

    return resources.filter((resource) => {
      const searchableFields = fields.map((field) => field.name);
      return searchableFields.some((fieldName) => {
        let value;
        if (fieldName === 'name') {
          value = resource?.name?.[0]?.given?.[0]
            ? resource?.name?.[0]?.given?.[0] + ' ' + resource?.name?.[0]?.family
            : resource?.name;
        } else {
          value = resource[fieldName];
        }
        return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
      });
    });
  };

  return (
    <>
      <Box px="lg" pt="sm" mt={65}>
        <TitleComponent title={resourceName} />
      </Box>

      <div className={classes.root} data-testid="search-control">
        <div className="tw-p-6 tw-pt-0" data-testid="search-control">
          <div className="tw-mb-4 tw-flex tw-justify-between tw-items-center"></div>

          <div className="tw-mb-4 tw-flex tw-justify-between tw-items-center">
            <div className="tw-relative tw-w-64">
              <input
                type="text"
                placeholder={`Search for ${resourceName}`}
                className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-teal-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="tw-absolute tw-left-3 tw-top-2.5">
                <IconSearch size={16} className="tw-text-gray-400" />
              </div>
            </div>

            <div className="tw-flex tw-justify-end tw-items-center">
              <button
                className="tw-bg-white tw-flex tw-items-center tw-mr-2 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300"
                onClick={() => setState({ ...stateRef.current, filterEditorVisible: true })}
              >
                <span className="tw-mr-1">
                  <IconAdjustmentsHorizontal size={16} />
                </span>
                Filters
              </button>

              <button
                onClick={props.onNew}
                className="tw-bg-teal-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg tw-flex tw-items-center tw-font-semibold"
              >
                <span className="tw-mr-2">
                  <IconPlus size={16} />
                </span>
                Add {resourceName}
              </button>

              {resourceName === 'Patient' && (
                <button
                  onClick={() => {
                    navigate('/Patient/UploadCSV');
                  }}
                  className="tw-bg-teal-500 tw-ml-1 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg tw-flex tw-items-center tw-font-semibold"
                >
                  <span className="tw-mr-2">
                    <IconPlus size={16} />
                  </span>
                  Upload {resourceName}
                </button>
              )}
            </div>
          </div>

          <div className="tw-relative tw-overflow-x-auto tw-bg-white tw-rounded-xl table-container">
            {isLoading ? (
              <Center style={{ height: '200px' }}>
                <Loader />
              </Center>
            ) : (
              <table className="tw-w-full">
                <thead>
                  <tr className="tw-bg-white ">
                    {fields.map((field, index) => (
                      <th
                        key={index}
                        className="tw-text-left tw-py-1 tw-px-4 tw-font-medium tw-text-sm tw-text-[#475467] tw-border-b"
                      >
                        <div className="tw-flex tw-justify-between tw-items-center ">
                          <span>
                            {buildFieldNameString(
                              field.name === 'active'
                                ? 'Status'
                                : field.name === 'type'
                                  ? 'Visit Type'
                                  : field.name === 'providedBy'
                                    ? 'Provider Groups'
                                    : field.name === 'start'
                                      ? 'Start Date'
                                      : field.name === 'end'
                                        ? 'End Date'
                                        : field.name
                            )}
                          </span>
                          <div>
                            <Menu shadow="md" width={240} position="bottom-end">
                              <Menu.Target>
                                <UnstyledButton className="tw-p-2">
                                  <Center className={classes.icon}>
                                    <IconAdjustmentsHorizontal size={14} stroke={1.5} />
                                  </Center>
                                </UnstyledButton>
                              </Menu.Target>
                              <SearchPopupMenu
                                search={props.search}
                                searchParams={field.searchParams}
                                onPrompt={(searchParam, filter) => {
                                  setState({
                                    ...stateRef.current,
                                    filterDialogVisible: true,
                                    filterDialogSearchParam: searchParam,
                                    filterDialogFilter: filter,
                                  });
                                }}
                                onChange={(result) => {
                                  emitSearchChange(result);
                                }}
                              />
                            </Menu>
                          </div>
                        </div>
                      </th>
                    ))}
                    <th className="tw-text-left tw-py-3 tw-px-2 tw-font-medium tw-text-sm tw-text-[#475467] tw-border-b">
                      Actions
                    </th>
                  </tr>

                  {/* {!props.hideFilters && (
                  <tr>
                    {fields.map((field, index) => (
                      <th
                        key={index}
                        className="tw-text-left tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600 tw-border-b"
                      >
                        {field.searchParams && (
                          <FilterDescription
                            resourceType={resourceType}
                            searchParams={field.searchParams}
                            filters={props.search.filters}
                          />
                        )}
                      </th>
                    ))}

                    <th className="tw-border-b"></th>
                  </tr>
                )} */}
                </thead>

                <tbody>
                  {filterResources(resources || [])?.map(
                    (resource: any, index) =>
                      resource && (
                        <tr
                          key={index}
                          className="tw-border-b hover:tw-bg-gray-50 tw-transition-colors tw-duration-200 tw-cursor-pointer"
                          data-testid="search-control-row"
                          onClick={() => {
                            if (location.pathname === '/ProviderGroup') {
                              resourceType = 'ProviderGroup';
                            } else if (location.pathname === '/Staff') {
                              resourceType = 'Staff';
                            }
                            if (location.pathname === '/Appointment') {
                              setIsAppointment(true);
                              setIsAppointmentId(resource.id);
                            } else {
                              navigate(`/${resourceType}/${resource.id}/edit`, {
                                state: {
                                  adminData: resource?.admins || resource?.admin,
                                  brands: resource?.brands,
                                  userId: resource?.userId,
                                  role: resource?.role || resource?.roles,
                                  insuranceNumber: resource?.insuranceNumber,
                                  coverageId: resource?.coverageId,
                                },
                              });
                            }
                          }}
                        >
                          {fields.map((field, index) => (
                            <td key={index} className="tw-p-5 tw-px-4 tw-text-sm">
                              {field.name === 'active' ? (
                                <span
                                  key={index}
                                  className={`tw-flex tw-justify-between tw-items-center tw-px-2 tw-rounded-lg tw-text-xs tw-w-fit tw-border-[1px] tw-text-[#344054] tw-font-medium`}
                                >
                                  <IconPointFilled
                                    className="tw-w-[16px] tw-mr-1"
                                    color={resource?.active ? '#17B26A' : '#F04438'}
                                  />
                                  {resource?.active ? 'Active' : 'Deactive'}
                                </span>
                              ) : field.name === 'name' &&
                                (resourceType === 'Practitioner' || resourceType === 'Organization') ? (
                                <span key={index} className="tw-flex">
                                  <div className="tw-flex tw-items-center">
                                    <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#F2F4F7] tw-border-[1px] tw-border-[#EAECF0] tw-rounded-full tw-w-[32px] tw-h-[32px]">
                                      <IconUser size={22} color="#475467" />
                                    </div>
                                    <div className="tw-ml-2">
                                      <div className="tw-font-medium tw-text-[#101828]">
                                        {resource?.name?.[0]?.given?.[0]
                                          ? resource?.name?.[0]?.given?.[0] + ' ' + resource?.name?.[0]?.family
                                          : resource?.name}
                                      </div>
                                      <div className="tw-font-normal tw-text-[#475467]">
                                        {
                                          resource?.telecom?.find(
                                            (telecom: { system: string }) => telecom.system === 'email'
                                          )?.value
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              ) : field.name === 'email' &&
                                (resourceType === 'Practitioner' ||
                                  resourceType === 'Organization' ||
                                  resourceType === 'Patient') ? (
                                resourceName === 'Brand' || resourceName === 'Provider Group' ? (
                                  resource?.admins?.map((admin: any) => <div>{admin?.email}</div>) ||
                                  resource?.admin?.email
                                ) : (
                                  resource?.telecom?.find((telecom: { system: string }) => telecom.system === 'email')
                                    ?.value
                                )
                              ) : field.name === 'phone' &&
                                (resourceType === 'Practitioner' ||
                                  resourceType === 'Organization' ||
                                  resourceType === 'Patient') ? (
                                resource?.telecom?.find((telecom: { system: string }) => telecom.system === 'phone')
                                  ?.value
                              ) : field.name === 'title' && resourceType === 'Practitioner' ? (
                                resource?.qualification?.[0].code.coding[0].display
                              ) : field.name === 'Practitioner' && resourceType === 'Appointment' ? (
                                resource?.participant?.[2]?.actor?.display
                              ) : (
                                renderValue(resource, field)
                              )}
                            </td>
                          ))}

                          <td className="tw-py-3 tw-px-4 tw-text-sm">
                            <div className="tw-flex tw-space-x-4">
                              <IconTrash
                                size={22}
                                className="tw-text-gray-600 hover:tw-text-red-500 tw-cursor-pointer"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              />

                              <IconPencil
                                size={22}
                                className="tw-text-gray-600 hover:tw-text-teal-500 tw-cursor-pointer"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (location.pathname === '/ProviderGroup') {
                                    resourceType = 'ProviderGroup';
                                  } else if (location.pathname === '/Staff') {
                                    resourceType = 'Staff';
                                  }
                                  if (location.pathname === '/Appointment') {
                                    setIsAppointment(true);
                                    setIsAppointmentId(resource.id);
                                  } else {
                                    navigate(`/${resourceType}/${resource.id}/edit`, {
                                      state: {
                                        adminData: resource?.admins || resource?.admin,
                                        brands: resource?.brands,
                                        userId: resource?.userId,
                                        role: resource?.role || resource?.roles,
                                        insuranceNumber: resource?.insuranceNumber,
                                        coverageId: resource?.coverageId,
                                      },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}

            {!isLoading && filterResources(resources || [])?.length === 0 && (
              <div className="tw-text-center tw-py-8">
                <p className="tw-text-gray-500 tw-text-lg">No results</p>
              </div>
            )}

            {/* Pagination */}
            {!isLoading && lastResult && (
              <div className="tw-flex tw-justify-between tw-items-center tw-p-3 tw-bg-white">
                <button
                  className="tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg hover:tw-bg-gray-50"
                  onClick={() => {
                    const newPage = Math.max(1, getPage(search) - 1);
                    emitSearchChange(setPage(search, newPage));
                  }}
                  style={{ visibility: getPage(search) > 1 ? 'visible' : 'hidden' }}
                >
                  <span className="tw-mr-1">
                    <IconArrowNarrowLeft size={16} />
                  </span>
                  Previous
                </button>
                {/* )} */}
                <div className="tw-flex tw-items-center">
                  {[...Array(Math.min(10, getTotalPages(search, lastResult)))].map((_, index) => (
                    <button
                      key={index}
                      className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                        index + 1 === getPage(search)
                          ? 'tw-bg-[#F9FAFB] tw-text-gray-700'
                          : 'tw-text-gray-700 tw-bg-white tw-border tw-border-gray-300 hover:tw-bg-gray-50'
                      }`}
                      onClick={() => emitSearchChange(setPage(search, index + 1))}
                    >
                      {index + 1}
                    </button>
                  ))}
                  {getTotalPages(search, lastResult) > 10 && (
                    <span className="tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700">...</span>
                  )}
                </div>
                <button
                  className="tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg hover:tw-bg-gray-50"
                  onClick={() => {
                    const newPage = Math.min(getTotalPages(search, lastResult), getPage(search) + 1);
                    emitSearchChange(setPage(search, newPage));
                  }}
                  style={{ visibility: getPage(search) < getTotalPages(search, lastResult) ? 'visible' : 'hidden' }}
                >
                  Next
                  <span className="tw-ml-1">
                    <IconArrowNarrowRight size={16} />
                  </span>
                </button>
              </div>
            )}
          </div>

          {outcome && (
            <div data-testid="search-error">
              <pre style={{ textAlign: 'left' }}>{JSON.stringify(outcome, undefined, 2)}</pre>
            </div>
          )}
          <SearchFieldEditor
            search={props.search}
            visible={stateRef.current.fieldEditorVisible}
            onOk={(result) => {
              emitSearchChange(result);
              setState({
                ...stateRef.current,
                fieldEditorVisible: false,
              });
            }}
            onCancel={() => {
              setState({
                ...stateRef.current,
                fieldEditorVisible: false,
              });
            }}
          />
          <SearchFilterEditor
            search={props.search}
            visible={stateRef.current.filterEditorVisible}
            onOk={(result) => {
              emitSearchChange(result);
              setState({
                ...stateRef.current,
                filterEditorVisible: false,
              });
            }}
            onCancel={() => {
              setState({
                ...stateRef.current,
                filterEditorVisible: false,
              });
            }}
          />
          <SearchFilterValueDialog
            key={state.filterDialogSearchParam?.code}
            visible={stateRef.current.filterDialogVisible}
            title={state.filterDialogSearchParam?.code ? buildFieldNameString(state.filterDialogSearchParam.code) : ''}
            resourceType={resourceType}
            searchParam={state.filterDialogSearchParam}
            filter={state.filterDialogFilter}
            defaultValue=""
            onOk={(filter) => {
              emitSearchChange(addFilter(props.search, filter.code, filter.operator, filter.value));
              setState({
                ...stateRef.current,
                filterDialogVisible: false,
              });
            }}
            onCancel={() => {
              setState({
                ...stateRef.current,
                filterDialogVisible: false,
              });
            }}
          />
        </div>
      </div>
      {isAppointment && (
        <Appointments opened={isAppointment} close={() => setIsAppointment(false)} appointmentId={isAppointmentId} />
      )}
    </>
  );
}

export const MemoizedSearchControl = memo(SearchControl);

// interface FilterDescriptionProps {
//   readonly resourceType: string;
//   readonly searchParams: SearchParameter[];
//   readonly filters?: Filter[];
// }

// function FilterDescription(props: FilterDescriptionProps): JSX.Element {
//   const filters = (props.filters ?? []).filter((f) => props.searchParams.find((p) => p.code === f.code));
//   if (filters.length === 0) {
//     return <span>No Filters</span>;
//   }

//   return (
//     <>
//       {filters.map((filter: Filter) => (
//         <div key={`filter-${filter.code}-${filter.operator}-${filter.value}`}>
//           {getOpString(filter.operator)}
//           &nbsp;
//           <SearchFilterValueDisplay resourceType={props.resourceType} filter={filter} />
//         </div>
//       ))}
//     </>
//   );
// }

function getPage(search: SearchRequest): number {
  return Math.floor((search.offset ?? 0) / (search.count ?? DEFAULT_SEARCH_COUNT)) + 1;
}

function getTotalPages(search: SearchRequest, lastResult: Bundle): number {
  const pageSize = search.count ?? DEFAULT_SEARCH_COUNT;
  const total = getTotal(search, lastResult);
  return Math.ceil(total / pageSize);
}
function getTotal(search: SearchRequest, lastResult: Bundle): number {
  let total = lastResult.total;
  if (total === undefined) {
    // If the total is not specified, then we have to estimate it
    total =
      (search.offset ?? 0) +
      (lastResult.entry?.length ?? 0) +
      (lastResult.link?.some((l) => l.relation === 'next') ? 1 : 0);
  }
  return total;
}
