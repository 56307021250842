/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { TextInput, Select, Button, MultiSelect } from '@mantine/core';
import { IconMinus, IconPlus, IconPointFilled } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { checkInputValue } from '../../utils/util';
import { DAYS_OF_WEEK, USA_STATES, USA_TIMEZONES } from '../../utils/constant';
import { useLocation, useParams } from 'react-router-dom';

interface CredentialsDetailsProps {
  updatedFormData?: any;
}

const CredentialsDetails = forwardRef<{ getData: () => any }, CredentialsDetailsProps>(({ updatedFormData }, ref) => {
  //console.log(updatedFormData)
  // Default state license data
  const providerId = useParams().id;
  const location = useLocation();
  const { role } = location?.state || {};
  const getDefaultStateLicenseData = () => ({
    stateName: '',
    licenseNumber: '',
    licenseStatus: 'true',
    licenseExpirationDate: '',
  });

  // Default DEA license data
  const getDefaultDEALicenseData = () => ({
    deaLicenseNumber: '',
    deaLicenseStatus: 'true',
    deaLicenseExpirationDate: '',
  });

  // Default DEA license data
  const getDefaultAvailableTimeData = () => ({
    daysOfWeek: '',
    availableStartTime: '',
    availableEndTime: '',
  });

  const [formData, setFormData] = useState<any>({
    npi: '',
    title: '',
    timezone: '',
    LicenseFields: updatedFormData?.LicenseFields?.length && updatedFormData?.LicenseFields,
    DEALicenseFields: updatedFormData?.DEALicenseFields?.length && updatedFormData?.DEALicenseFields,
    AvailableTime: updatedFormData?.availableTime?.length && updatedFormData?.availableTime,
  });
  const [isLoading, setIsLoading] = useState(providerId ? false : true);

  useEffect(() => {
    setTimeout(() => {
      setFormData((prevData: any) => ({
        ...prevData,
        ...updatedFormData,
      }));
    }, 2000);
  }, [updatedFormData]);

  // Separate state variables for state licenses
  const [stateLicenseFields, setStateLicenseFields] = useState<any>([
    { fieldId: 1, formData: getDefaultStateLicenseData() },
  ]);

  const [deaLicenseFields, setDEALicenseFields] = useState<any>([{ fieldId: 1, formData: getDefaultDEALicenseData() }]);

  const [availableTime, setAvailableTime] = useState<any>([{ fieldId: 1, formData: getDefaultAvailableTimeData() }]);

  // set data previous state and update the state
  useEffect(() => {
    setStateLicenseFields((prevFields: any) =>
      updatedFormData?.LicenseFields.length ? updatedFormData?.LicenseFields : prevFields
    );
    setDEALicenseFields((prevFields: any) =>
      updatedFormData?.DEALicenseFields.length ? updatedFormData?.DEALicenseFields : prevFields
    );
    setAvailableTime((prevFields: any) =>
      updatedFormData?.availableTime.length
        ? updatedFormData?.availableTime.map((time: any, index: number) => ({
            fieldId: index + 1,
            formData: time,
          }))
        : prevFields
    );
    setTimeout(() => {
      setIsLoading(true);
    }, 2000);
  }, [updatedFormData]);

  const selectedDaysOfWeek = updatedFormData?.availableTime?.[0]?.daysOfWeek?.map((days: { id: string; name: string }) => days);

  // Separate add functions for state licenses
  const addStateLicenseDetails = () => {
    setStateLicenseFields((prevFields: any) => [
      ...prevFields,
      {
        fieldId: prevFields.length > 0 ? prevFields[prevFields.length - 1].fieldId + 1 : 1,
        formData: getDefaultStateLicenseData(),
      },
    ]);
  };


  // Separate add functions for DEA licenses
  const addDEALicenseDetails = () => {
    setDEALicenseFields((prevFields: any) => [
      ...prevFields,
      {
        fieldId: prevFields.length > 0 ? prevFields[prevFields.length - 1].fieldId + 1 : 1,
        formData: getDefaultDEALicenseData(),
      },
    ]);
  };

  // Separate add functions for Available Time
  const addAvailableTime = () => {
    setAvailableTime((prevFields: any) => [
      ...prevFields,
      {
        fieldId: prevFields.length > 0 ? prevFields[prevFields.length - 1].fieldId + 1 : 1,
        formData: getDefaultAvailableTimeData(),
      },
    ]);
  };

  // Separate remove functions for state licenses
  const removeStateLicenseDetails = (fieldId: any) => {
    setStateLicenseFields((prevFields: any) => prevFields.filter((field: any) => field.fieldId !== fieldId));
  };

  // Separate remove functions for DEA licenses
  const removeDEALicenseDetails = (fieldId: any) => {
    setDEALicenseFields((prevFields: any) => prevFields.filter((field: any) => field.fieldId !== fieldId));
  };

  // Separate remove functions for Available Time
  const removeAvailableTime = (fieldId: any) => {
    setAvailableTime((prevFields: any) => prevFields.filter((field: any) => field.fieldId !== fieldId));
  };

  // Separate handle input change functions for state licenses
  const handleStateLicenseInputChange = (e: any, fieldName: string, fieldId: any) => {
    let name: string, value: any;

    // Check if the field name is stateName, licenseStatus, or licenseExpirationDate for getting the these values from the select component or date component in e variable
    if (fieldName === 'licenseStatus' || fieldName === 'licenseExpirationDate' || fieldName === 'stateName') {
      name = fieldName;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setStateLicenseFields((prevFields: any) =>
      prevFields.map((field: { fieldId: number; formData: any }) =>
        field.fieldId === fieldId ? { ...field, formData: { ...field.formData, [name]: value } } : field
      )
    );
  };

  // Separate handle input change functions for DEA licenses
  const handleDEALicenseInputChange = (e: any, fieldName: string, fieldId: any) => {
    let name: string, value: any;

    // Check if the field name is deaLicenseStatus or deaLicenseExpirationDate for getting the these values from the select component or date component in e variable
    if (fieldName === 'deaLicenseStatus' || fieldName === 'deaLicenseExpirationDate') {
      name = fieldName;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setDEALicenseFields((prevFields: any) =>
      prevFields.map((field: { fieldId: number; formData: any }) =>
        field.fieldId === fieldId ? { ...field, formData: { ...field.formData, [name]: value } } : field
      )
    );
  };

  // Handle input change for available time
  const handleAvailableTimeInputChange = (e: any, fieldName: string, fieldId: any) => {
    let name: string, value: any;

    // Check if the field name is availableStartTime or availableEndTime for getting the these values from the select component or date component in e variable
    if (fieldName === 'daysOfWeek') {
      name = fieldName;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setAvailableTime((prevFields: any) =>
      prevFields.map((field: { fieldId: number; formData: any }) =>
        field.fieldId === fieldId ? { ...field, formData: { ...field.formData, [name]: value } } : field
      )
    );
  };

  // Handle input change for main form data
  const handleInputChange = (e: any, fieldName: string) => {
    let name: string, value: any;
    if (fieldName === 'timezone' || fieldName === 'days' || fieldName === 'title') {
      name = fieldName;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const checkedValue = checkInputValue(name, value);
    if (checkedValue !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checkedValue,
      }));
    }
  };

  // Update form data when state license fields change
  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      LicenseFields: stateLicenseFields,
      timezone: role?.[0]?.timezone,
    }));
  }, [stateLicenseFields]);


  // Update form data when DEA license fields change
  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      DEALicenseFields: deaLicenseFields,
    }));
  }, [deaLicenseFields]);

  // Update form data when Available Time fields change
  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      AvailableTime: availableTime,
    }));
  }, [availableTime]);

  // Update form data when main form data change
  useImperativeHandle(ref, () => ({
    getData: () => formData,
  }));

  return (
    <>
      {isLoading ? (
        <div className="tw-max-w-3xl tw-mt-5">
          <h2 className="tw-text-2xl tw-text-[#101828] tw-font-semibold tw-mb-6">Credentials</h2>

          <div className="tw-space-y-4">
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">NPI</label>
                <TextInput
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  type="number"
                  value={formData.npi}
                  name="npi"
                  placeholder="NPI Number"
                  onChange={(e) => handleInputChange(e, 'npi')}
                  maxLength={10}
                />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Title</label>

                <Select
                  styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                  data={[
                    { value: 'MD', label: 'MD' },
                    { value: 'DO', label: 'DO' },
                    { value: 'MP', label: 'MP' },
                    { value: 'PA', label: 'PA' },
                  ]}
                  name="title"
                  placeholder="Title"
                  value={formData.title}
                  onChange={(e) => handleInputChange(e, 'title')}
                />
              </div>
            </div>

            <div className="tw-mt-[1rem]">
              <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">State Licenses</label>

              {stateLicenseFields?.map((detail: any, index: number) => (
                <div key={detail.fieldId}>
                  {index > 0 && (
                    <button
                      type="button"
                      style={{
                        backgroundColor: '#FFEAEA',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        margin: '0 8px',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => removeStateLicenseDetails(detail.fieldId)}
                    >
                      <IconMinus size={20} style={{ margin: '6px' }} />
                    </button>
                  )}

                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">State</label>
                      <Select
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        data={USA_STATES}
                        placeholder="State Name"
                        defaultValue={detail?.formData?.stateName}
                        name="stateName"
                        searchable
                        onChange={(e) => handleStateLicenseInputChange(e, 'stateName', detail.fieldId)}
                      />
                    </div>

                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Number
                      </label>
                      <TextInput
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        name="licenseNumber"
                        value={detail?.formData?.licenseNumber}
                        placeholder="License Number"
                        onChange={(e) => handleStateLicenseInputChange(e, 'licenseNumber', detail.fieldId)}
                      />
                    </div>
                  </div>

                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Expiration Date
                      </label>
                      <DateInput
                        onChange={(e: any) => handleStateLicenseInputChange(e, 'licenseExpirationDate', detail.fieldId)}
                        value={
                          detail?.formData?.licenseExpirationDate
                            ? new Date(detail?.formData?.licenseExpirationDate)
                            : null
                        }
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        placeholder="License Expiration Date"
                        valueFormat="MM-DD-YYYY"
                        name="licenseExpirationDate"
                      />
                    </div>

                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Status
                      </label>
                      <div className="tw-relative tw-flex tw-items-center tw-justify-between">
                        <Select
                          styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px', paddingLeft: '30px' } }}
                          data={[
                            { value: 'true', label: 'Active' },
                            { value: 'false', label: 'Inactive' },
                          ]}
                          onChange={(e) => handleStateLicenseInputChange(e, 'licenseStatus', detail.fieldId)}
                          name="licenseStatus"
                          placeholder="License Status"
                          value={detail?.formData?.licenseStatus}
                          className="tw-w-full"
                        />
                        <div className="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
                          <IconPointFilled
                            className="tw-w-[18px]"
                            color={detail?.formData?.licenseStatus === 'true' ? '#17B26A' : '#F04438'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                style={{
                  backgroundColor: 'transparent',
                  padding: '0.5rem 1rem',
                  fontWeight: 600,
                  color: '#121926',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  fontSize: '14px',

                  '&:hover': {
                    backgroundColor: '#F9FAFB',
                  },
                }}
                type="button"
                onClick={addStateLicenseDetails}
              >
                <IconPlus size={20} />
                Add Licenses
              </Button>
            </div>

            <div className="tw-mt-[1rem]">
              <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">DEA Licenses</label>

              {deaLicenseFields?.map((detail: any, index: number) => (
                <div key={detail.fieldId}>
                  {index > 0 && (
                    <button
                      type="button"
                      style={{
                        backgroundColor: '#FFEAEA',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        margin: '0 8px',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => removeDEALicenseDetails(detail.fieldId)}
                    >
                      <IconMinus size={20} style={{ margin: '6px' }} />
                    </button>
                  )}
                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Number
                      </label>
                      <TextInput
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        value={detail?.formData?.deaLicenseNumber}
                        name="deaLicenseNumber"
                        placeholder="License Number"
                        onChange={(e) => handleDEALicenseInputChange(e, '', detail.fieldId)}
                      />
                    </div>
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Status
                      </label>

                      <div className="tw-relative tw-flex tw-items-center tw-justify-between">
                        <Select
                          styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px', paddingLeft: '30px' } }}
                          data={[
                            { value: 'true', label: 'Active' },
                            { value: 'false', label: 'Inactive' },
                          ]}
                          name="deaLicenseStatus"
                          placeholder="License Status"
                          value={detail?.formData?.deaLicenseStatus}
                          onChange={(e) => handleDEALicenseInputChange(e, 'deaLicenseStatus', detail.fieldId)}
                          className="tw-w-full"
                        />
                        <div className="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
                          <IconPointFilled
                            className="tw-w-[18px]"
                            color={detail?.formData?.deaLicenseStatus === 'true' ? '#17B26A' : '#F04438'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                        License Expiration Date
                      </label>
                      <DateInput
                        onChange={(e: any) =>
                          handleDEALicenseInputChange(e, 'deaLicenseExpirationDate', detail.fieldId)
                        }
                        value={
                          detail?.formData?.deaLicenseExpirationDate
                            ? new Date(detail?.formData?.deaLicenseExpirationDate)
                            : null
                        }
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        placeholder="DEA License Expiration Date"
                        valueFormat="MM-DD-YYYY"
                        name="deaLicenseExpirationDate"
                      />
                    </div>
                  </div>
                </div>
              ))}

              <Button
                style={{
                  backgroundColor: 'transparent',
                  padding: '0.5rem 1rem',
                  fontWeight: 600,
                  color: '#121926',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: '#F9FAFB',
                  },
                }}
                type="button"
                onClick={addDEALicenseDetails}
              >
                <IconPlus size={20} />
                Add DEA Licenses
              </Button>
            </div>

            <div className="tw-mt-[1rem]">
              <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">Timezone</label>

              <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full tw-px-2 tw-mb-4 md:tw-mb-0">
                  <Select
                    styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                    data={USA_TIMEZONES}
                    name="timezone"
                    placeholder="Timezone"
                    value={formData.timezone}
                    onChange={(e) => handleInputChange(e, 'timezone')}
                  />
                </div>
              </div>
            </div>

            <div className="tw-mt-[1rem]">
              <label className="tw-block tw-text-lg tw-font-semibold  tw-text-[#101828] tw-mb-3">Schedule</label>
              {availableTime?.map((detail: any, index: number) => (
                <div key={detail.fieldId}>
                  {index > 0 && (
                    <button
                      type="button"
                      style={{
                        backgroundColor: '#FFEAEA',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        margin: '0 8px',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => removeAvailableTime(detail.fieldId)}
                    >
                      <IconMinus size={20} style={{ margin: '6px' }} />
                    </button>
                  )}
                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <MultiSelect
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        name="daysOfWeek"
                        placeholder="Days of the week"
                        defaultValue={selectedDaysOfWeek}
                        data={DAYS_OF_WEEK}
                        onChange={(e) => handleAvailableTimeInputChange(e, 'daysOfWeek', detail.fieldId)}
                      />
                    </div>
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <TextInput
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        name="availableStartTime"
                        placeholder="Available Start Time(eg. 09:00:00)"
                        value={detail?.formData?.availableStartTime}
                        onChange={(e) => handleAvailableTimeInputChange(e, 'availableStartTime', detail.fieldId)}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                    <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                      <TextInput
                        styles={{ input: { backgroundColor: '#F9FAFB', borderRadius: '8px' } }}
                        name="availableEndTime"
                        placeholder="Available End Time(eg. 17:00:00)"
                        defaultValue={detail?.formData?.availableEndTime}
                        onChange={(e) => handleAvailableTimeInputChange(e, 'availableEndTime', detail.fieldId)}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button
                style={{
                  backgroundColor: 'transparent',
                  padding: '0.5rem 1rem',
                  fontWeight: 600,
                  color: '#121926',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: '#F9FAFB',
                  },
                }}
                type="button"
                onClick={addAvailableTime}
              >
                <IconPlus size={20} />
                Add Available Time
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-mb-2.5"></div>
          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-mb-2.5"></div>
          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-mb-2.5"></div>
          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-mb-2.5"></div>
          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full"></div>
        </>
      )}
    </>
  );
});

export default CredentialsDetails;
